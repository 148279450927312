<template>
	<div class="footer">
		<div class="footerRow">
			<v-btn plain dense class="navLink">Changelog</v-btn>
			<v-btn plain dense class="navLink">Discord</v-btn>
			<v-btn plain dense class="navLink">Github</v-btn>
		</div>
		<v-divider></v-divider>
		<div class="footerRow">
			&#169; 2022 - ORACLE
		</div>
	</div>
</template>

<script>

	export default {

	};
</script>

<style scoped>
	.footer {
		background: #212121;
		text-align: center;
		width: 100%;
		padding: 20px;
		gap: 20px;
		display: flex;
		flex-direction: column;
	}
	.footerRow {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>
