<template>
	<v-app>
		<v-main style="overflow-y:scroll; max-height:100vh">
			<div :class="$vuetify.breakpoint.name == 'xs' ? 'grid-container smallgrid' : 'grid-container'">
				<div
					class="grid-item comic-preview-container"
					:style="$vuetify.breakpoint.name == 'xs' ? 'position:absolute' : 'position:relative'"
				>
					<div class="comic-preview-grid" v-if="images.length > 0">
						<div class="image-wrapper" v-for="i in 16" :key="i">
							<v-img
								eager
								:src="require('../assets/previewImages/' + i + '.jpg')"
								:aspect-ratio="2 / 3"
								width="200px"
								class="comic-preview-card"
							></v-img>
						</div>
					</div>
				</div>
				<div class="grid-item header">
					<v-btn plain dense class="navLink">Info</v-btn>
					<v-btn plain dense class="navLink">Supported Sites</v-btn>
					<!-- <loginButton small style="position:absolute;right:10px"/> -->
				</div>
				<div class="grid-item grid-title">
					<div class="title-wrapper">
						<h1>Comic Reader<span>ALPHA</span></h1>
						<div v-show="user.name == ''">
							<span>Read all your favorite comics in one place!</span>
							<loginButton class="mt-5" />
						</div>
						<div v-show="user.name != ''">
							<span>Welcome back {{ user.name }}!</span>
							<loginButton class="mt-5" dashboard />
						</div>
					</div>

					<!-- <button type="button" class="loginButton" @click="login()">
						Comic Reader
						<svg class="loginButtonArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.832 268.832">
							<path
								d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z"
							/>
						</svg>
					</button> -->
				</div>
				<div class="grid-item footer"></div>
			</div>
			<infoContainer />
			<preview />

			<loginButton
				v-show="user.name == ''"
				class="mt-8 mb-8"
				style="margin-right:auto; margin-left:auto; width:min-content;"
			/>
			<loginButton
				v-show="user.name != ''"
				class="mt-8 mb-8"
				style="margin-right:auto; margin-left:auto; width:min-content;"
				dashboard
			/>
			<infoFooter />
			<alert v-model="showAlert" text="You are not whitelisted" :timeout="400000" />
		</v-main>
	</v-app>
</template>

<style scoped>
	.loginButton {
		background-color: transparent !important;
		box-shadow: none;
		font-size: 3.5rem;
		width: min-content;
		text-align: left;
		line-height: 3rem;
		font-weight: 600;
		padding: 20px;
		margin-left: 20px;
		position: relative;
	}
	/* 	.loginButton:hover {
 		padding-left: 30px;
		padding-right: 30px; 
	} */
	.loginButton:hover > .loginButtonArrow {
		fill: #2196f3;
		left: calc(115% - 25px);
	}
	.loginButtonArrow {
		width: 50px;
		position: absolute;
		fill: transparent;
		top: calc(50% - 25px);
		left: calc(105% - 25px);
		transition: all 0.35s;
	}

	.title {
		text-align: center;
		font-size: 1.5rem !important;
		font-weight: 600;
	}

	.grid-container {
		max-height: 100vh;
		height: 100vh;
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-template-rows: 0.1fr 1fr 0.1fr;
		grid-template-areas:
			"preview header"
			"preview title"
			"preview footer";
	}
	.smallgrid {
		grid-template-areas:
			"header header"
			"title title"
			"preview footer";
	}
	.comic-preview-container {
		width: 100%;
		height: 100vh;
		grid-area: preview;
		max-height: 100vh !important;
		position: relative;
		overflow: hidden;
	}
	.comic-preview-grid {
		display: grid;
		position: absolute;
		width: inherit;
		filter: blur(1px);
		opacity: 0.5;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		grid-template-columns: unset;
		grid-template-rows: unset;
		gap: 10px;
		animation: slide 60s linear infinite;
		transition-delay: 1ms;
	}

	@keyframes slide {
		0% {
			transform: translateY(0) !important;
		}
		100% {
			transform: translateY(-56.3%);
		}
	}

	.comic-preview-card {
		border-radius: 2px;
		min-height: 0;
	}
	.image-wrapper {
		display: flex;
		justify-content: center;
	}

	.header {
		grid-area: header;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 15px;
		z-index: 1;
	}
	.grid-title {
		grid-area: title;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		z-index: 1;
	}
	.footer {
		grid-area: footer;
	}

	.grid-item {
		/* border: 1px solid white; */
		min-height: 0;
		min-width: 0;
		max-height: 100%;
	}

	.bgImage {
		filter: blur(4px);
		opacity: 0.3;
	}
	.mainContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.title-wrapper {
		display: flex;
		gap: 10px;
		flex-direction: column;
		margin-left: 10px;
	}
	.title-wrapper h1 {
		/* margin-left: 5px; */
		width: min-content;
		font-size: 4rem;
		line-height: 3.2rem;
	}
	.title-wrapper h1 > span {
		font-size: 14px;
	}

	.title-wrapper span {
		font-size: 18px;
		line-height: 30px;
		letter-spacing: 0.149em;
		font-weight: 300;
		font-style: normal;
	}
</style>

<script>
	import { mapState, mapActions } from "vuex";
	import infoContainer from "../components/StartPage/info-container.vue";
	import infoFooter from "../components/StartPage/info-footer.vue";
	import preview from "../components/StartPage/preview.vue";
	import loginButton from "../components/StartPage/login-button.vue";
	import bookButton from "../components/StartPage/book-button.vue";
	import alert from "../components/alert.vue";
	export default {
		data() {
			return {
				images: [],
				showAlert: false,
			};
		},
		components: {
			infoFooter,
			preview,
			infoContainer,
			loginButton,
			bookButton,
			alert,
		},
		mounted() {
			this.importAll(require.context("../assets/previewImages/", true, /(\.jpg|\.png)$/));
		},
		computed: {
			...mapState(["user"]),
		},
		methods: {
			...mapActions(["getUser"]),
			login() {
				window.location.replace("/api/discord/login");
			},
			importAll(r) {
				r.keys().forEach((key) => this.images.push({ pathLong: r(key), pathShort: key.split("/")[1] }));
			},
		},
	};
</script>
