<template>
<div>
    <v-dialog transition="dialog-top-transition" width="fit-content" v-model="dialog" dark overlay-opacity="0.9" persistent>
        <template>
            <v-btn large @click="login()">DISCORD LOGIN</v-btn>
        </template>

    </v-dialog>
    <div class="text-center ma-2">
        <v-snackbar v-model="whitelistError" timeout="-1">
            <span>You are not whitelisted.</span>
            <br>
            <span>Contact <b>ORACLE#7672</b> on Discord.</span>
        </v-snackbar>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            whitelistError: false
        }
    },
    mounted() {
        this.$root.bus.$on("showLogin", (err = null) => {
            if(err.message == '403')
              this.whitelistError = true
            this.dialog = true
        });
    },
    methods: {
        login() {
            window.location.replace('/api/discord/login')
        }
    }
}
</script>
