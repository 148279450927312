<template>
<v-dialog transition="dialog-top-transition" width="fit-content" v-model="dialog" dark overlay-opacity="0.9">
    <template>
        <v-card max-width="700">
            <v-list-item>
                <v-list-item-avatar tile height="60%" width="40%">
                    <v-img :alt="comic.title" :src="comic.image" dark>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="headline mb-1 purple--text">{{comic.title}}</v-list-item-title>
                    <v-list-item-title class="title mb-1" v-if="comic.latestChapter">{{comic.latestChapter.chapter}} Chapters</v-list-item-title>
                    <div style="max-height:175px;height:175px;overflow:auto;">{{comic.description}}</div>

            <v-row class="mt-4 ml-1">
                <v-btn color="primary" text  @click="addComic">
                        Add
                    </v-btn>
                    <v-btn color="primary" text @click="dialog=false">
                        Close
                    </v-btn>
            </v-row>
                </v-list-item-content>

            </v-list-item>
        </v-card>

    </template>
</v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        comic: {
            "title": "",
            "chapter": 0,
            "description": "",
            "image": "",
            "url": "",
            "mainURL": "",
            "latestChapter": {
                "chapter": 0,
                "url": ""
            },
            "firstChapter": {
                "chapter": 0,
                "url": ""
            },
            "id": 0
        }
    }),
    mounted() {
        this.$root.bus.$on("showWantToAddComic", (comic) => {
            this.dialog = true;
            this.comic = comic;
        });
    },
    methods: {
    addComic() {
        this.dialog = false;
            this.$root.bus.$emit("addComic", {
                chapterURL: this.comic.url,
                mainURL: this.comic.mainURL,
                provider: 'Custom',
                add:true,
                ln:this.comic.ln
            });
        },
    },
};
</script>
