import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: {
			name: "",
			icon: "",
			comics: [],
		},
	},
	mutations: {
		updateUser(state, newUser) {
			state.user = newUser;
		},
		updateComics(state, comics) {
			state.user.comics = comics;
		},
		updateComic(state, newComic) {
			const comic = state.user.comics.find((comic) => comic.id == newComic.id);
			Object.keys(comic).forEach((key) => Vue.set(comic, key, newComic[key]));
		},
		removeComic(state, id) {
			const comicIndex = state.user.comics.findIndex((comic) => comic.id == id);
			state.user.comics.splice(comicIndex, 1);
		},
	},
	actions: {
		async getUser(ctx) {
			const result = await fetch("/database/getData", { credentials: "same-origin" });
			if (result.status >= 200 && result.status <= 299) {
				const user = await result.json();
				if (user.name) {
					ctx.commit("updateUser", { name: user.name, icon: user.icon, comics: user.comics });
					return true;
				}
			}
			return false;
		},
		async addComic(ctx, info) {
			const result = await fetch("/database/addComic", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					chapterURL: info.chapterURL,
					mainURL: info.mainURL,
					provider: info.provider,
					add: info.add,
					ln: info.ln,
				}),
				credentials: "same-origin",
			});

			if (result.status >= 200 && result.status <= 299) {
				const comics = await result.json();
				ctx.commit("updateComics", comics);
				return true;
			}
			return false;
		},
		async deleteComic(ctx, id) {
			const result = await fetch("/database/deleteComic", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					id: id,
				}),
				credentials: "same-origin",
			});

			if (result.status >= 200 && result.status <= 299) {
				ctx.commit("removeComic", id);
				return true;
			}
			return false;
		},
		async archiveComic(ctx, id) {
			const result = await fetch("/database/archive", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					id: id,
				}),
				credentials: "same-origin",
			});

			if (result.status >= 200 && result.status <= 299) {
				const archivedComic = await result.json();

				ctx.commit("updateComic", archivedComic);
				return { archived: archivedComic.archive };
			}
			return null;
		},
		async updateComic(ctx, { newUrl, id }) {
			const result = await fetch("/database/updateComic", {
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					newUrl: newUrl,
					id: id,
				}),
			});
			if (result.status >= 200 && result.status <= 299) {
				const newComic = await result.json();
				ctx.commit("updateComic", newComic);
				return newComic;
			}
			return null;
		},
	},

	modules: {},
});
