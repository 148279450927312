<template>
	<v-dialog
		scrollable
		:hide-overlay="fs"
		:fullscreen="fs"
		transition="dialog-top-transition"
		v-if="dialog"
		v-model="dialog"
		dark
		width="75%"
		overlay-opacity="0.9"
		@click:outside="close()"
		content-class="elevation-0 dialogClass"
	>
		<template>
			<div class="loaderContainer" ref="loaderContainer">
				<v-row v-if="comic" style="margin: 0; color: white;font-size:2vh;align-items:center">
					<v-btn
						icon
						@click="
							(e) => {
								this.close();
								this.$root.bus.$emit('readComic', comic);
							}
						"
						class="topButton"
					>
						<v-icon v-show="!showLoader" style="font-size:3vh;display:none">mdi-refresh</v-icon>
						<v-progress-circular
							v-show="showLoader"
							size="25"
							style="height: 2.5vh;width: 2.5vh;"
							indeterminate
						></v-progress-circular>
					</v-btn>
					{{ comic.chapter }}
					<v-spacer></v-spacer>
					<v-btn icon @click="toggleFullscreen" class="topButton">
						<v-icon style="font-size:3vh;" v-show="!fs">mdi-fullscreen</v-icon>
						<v-icon style="font-size:3vh;" v-show="fs">mdi-fullscreen-exit</v-icon>
					</v-btn>
					<v-btn
						icon
						@click="
							() => {
								dialog = false;
								close();
							}
						"
						class="topButton"
					>
						<v-icon style="font-size:3vh;">mdi-close</v-icon>
					</v-btn>
				</v-row>
				<div class="loader">
					<div ref="comicContainer" class="comicContainer" tabindex="0">
						<div ref="forceHeight">
							<img
								v-for="(img, index) in images"
								:key="img.id"
								:ref="'img-' + index"
								style="display: none;
    margin: 0.1px auto 0;
    max-width: 100%;
    height: auto;"
							/>
							<p v-for="textBlock in text" :key="textBlock.id">
								{{ textBlock.text }}
							</p>
							<div v-if="showError && images.length == 0 && comic">
								<v-row
									justify="center"
									style="margin: 0; color: white;font-size:4vh;display: flex;flex-direction: column;align-items: center;text-align: center;"
								>
									Something went wrong :(<br />
									Visit the original Website to read the comic.
									<v-btn @click="openComic(comic.url)">
										{{ comic.title }}
									</v-btn>
								</v-row>
							</div>
						</div>
					</div>

					<v-row justify="center" style="margin: -1vh 0 1vh;">
						<v-alert
							type="error"
							:value="showErrorBox"
							style="position:absolute;z-index:1;"
							transition="slide-y-reverse-transition"
							>Was not able to fetch all images.</v-alert
						>
					</v-row>
					<v-row justify="center" style="margin: 1vh 0px -4vh">
						<v-btn
							:disabled="showError || !comic || (comic.chapters && +comic.chapter <= +comic.chapters[0].chapter)"
							@click="change(-1)"
							class="customButton"
							>Prev</v-btn
						>
						<v-btn
							:disabled="
								showError ||
									!comic ||
									(comic.chapters && +comic.chapter >= +comic.chapters[comic.chapters.length - 1].chapter)
							"
							@click="change(1)"
							class="customButton"
							>Next</v-btn
						>
					</v-row>
				</div>
			</div>
		</template>
	</v-dialog>
</template>

-->

<style>
	.dialogClass {
		overflow: unset !important;
	}</style
><style scoped>
	.loader {
		height: 90%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.loaderContainer {
		/* height: 90vh; */
		color: #cac4c4;
		background: #121212;
		border-radius: 1vh;
		padding: 2vh;
		padding-top: 1vh;
		padding-bottom: 1vh;
	}

	.comicContainer {
		height: 100vh;
		width: 100%;
		overflow: auto;
	}

	.comicContainer:focus {
		outline: none;
	}

	.customButton {
		height: 4vh !important;
		width: 8vh !important;
		min-width: 0 !important;
		font-size: 2vh !important;
		padding: 0 !important;
		border-radius: 0.5vh !important;
	}

	.topButton {
		height: 4vh !important;
		width: 4vh !important;
		min-width: 0 !important;
		font-size: 2vh !important;
		padding: 0 !important;
		border-radius: 0.5vh !important;
	}
</style>

<script>
	import { mapActions } from "vuex";
	export default {
		data: () => ({
			dialog: false,
			url: "",
			visible: true,
			showLoader: true,
			comic: null,
			images: [],
			text: [],
			reloadKey: 0,
			showError: false,
			showErrorBox: false,
			lastImageIndex: 0,
			intervals: [],
			fs: true,

			cache: null,
		}),
		watch: {
			fs: {
				immediate: true,

				handler(a, b) {
					if (a) {
						document.documentElement.style.overflowY = "hidden";
					} else {
						document.documentElement.style.overflowY = "auto";
					}
				},
			},
		},

		mounted() {
			/*   this.$refs.loaderContainer.style.setProperty('max-height', `${0.9*window.innerHeight}px`)
		         const readerHeight = () => {
		             this.$refs.loaderContainer.style.setProperty('max-height', `${0.9*window.innerHeight}px`)
		         }
		         window.addEventListener('resize', readerHeight) */

			this.$root.bus.$on("closeComic", () => {
				this.close();
			});
			this.$root.bus.$on(
				"readComic",
				(async (comic, chapter = null) => {
					this.dialog = true;
					switch (this.$vuetify.breakpoint.name) {
						case "xs":
							this.fs = true;
					}
					if (window.localStorage.fs) this.fs = window.localStorage.fs === "true";
					setTimeout(() => this.$refs.comicContainer.focus(), 50);
					this.images = [];
					this.text = [];
					this.comic = comic;
					if (chapter == null) chapter = this.comic.chapters.find((ch) => ch.chapter == this.comic.chapter);
					if (chapter.url != this.comic.url) {
						this.comic.chapter = chapter.chapter;
						this.comic.url = chapter.url;
						this.updateComic({newUrl:chapter.url, id:this.comic.id});
						//NON BLOCKING UPDATE
						/* if (result == null) {
							console.error("Could not fetch new chapter.");
							return;
						}
						this.comic = result; */
					}

					if (this.comic.ln) {
						this.url = `/database/showNovel?&url=${chapter.url}`;
					} else {
						this.url = `/database/showComic?&url=${chapter.url}`;
					}
					if (this.cache && this.url != this.cache.url) {
						this.cache = null;
					}
					this.showLoader = true;
					let scrollInfo = window.localStorage[this.comic.id];
					this.waitForRef("forceHeight").then(
						(() => {
							this.$refs.forceHeight.style.height = "0px";
							if (scrollInfo) {
								scrollInfo = JSON.parse(scrollInfo);
								if (scrollInfo.chapter == this.comic.chapter) {
									this.$refs.forceHeight.style.height = scrollInfo.height + "px";
									this.$refs.comicContainer.scrollTop = scrollInfo.scroll;
									window.localStorage.removeItem(this.comic.id);
								}
							}
						}).bind(this)
					);
					this.waitForRef("comicContainer").then(
						(() => {
							this.$refs.comicContainer.onscroll = this.checkScroll;
						}).bind(this)
					);
					this.fetchContent(this.url);
				}).bind(this)
			);

			//Navigation with Arrowkeys / A / D
			document.body.addEventListener("keydown", (event) => {
				if (!this.dialog) return;
				if (
					(event.code == "ArrowLeft" || event.code == "KeyA") &&
					!this.showError &&
					this.comic &&
					(!this.comic.chapters || +this.comic.chapter > +this.comic.chapters[0].chapter)
				) {
					this.change(-1);
				} else if (
					(event.code == "ArrowRight" || event.code == "KeyD") &&
					!this.showError &&
					this.comic &&
					(!this.comic.chapters || +this.comic.chapter < +this.comic.chapters[this.comic.chapters.length - 1].chapter)
				) {
					this.change(1);
				}
			});
		},

		methods: {
			...mapActions(["updateComic"]),
			checkScroll() {
				const scrollPercentage = this.$refs.comicContainer.scrollTop / this.$refs.comicContainer.scrollHeight;
				if (scrollPercentage >= 0.6 && this.cache == null) {
					this.cache = {};
					this.change(1, true);
				}
			},
			toggleFullscreen() {
				this.fs = !this.fs;
				window.localStorage.setItem("fs", this.fs);
			},
			// Waits for reference to be initialised
			waitForRef(ref, time = Date.now()) {
				return new Promise((res, rej) => {
					const r = this.$refs[ref];
					if (!r) {
						if (Date.now() - time < 2000) {
							setTimeout(() => {
								return res(this.waitForRef(ref, time));
							}, 10);
						} else {
							return rej(-1);
						}
					} else {
						return res(r);
					}
				});
			},
			close() {
				this.fs = false;
				this.url = "";
				this.showError = false;
				this.intervals.forEach((interval) => {
					clearInterval(interval);
				});
				this.intervals = [];
				if (this.comic && this.$refs.comicContainer)
					window.localStorage.setItem(
						this.comic.id,
						JSON.stringify({
							chapter: this.comic.chapter,
							scroll: this.$refs.comicContainer.scrollTop,
							height: this.$refs.comicContainer.scrollHeight,
						})
					);
				this.dialog = false;
			},
			// OLD: Gets chapter from url
			getChapter(url) {
				let chapter = "";

				for (var i = url.length - 1; i >= 0; i--) {
					const char = url[i];
					const result = parseInt(char);

					if (!isNaN(result)) {
						chapter = char + chapter;
					}
					if (isNaN(result) && i != url.length - 1) {
						break;
					}
				}
				return {
					chapter: chapter,
					rawUrl: url.substring(0, i + 1),
				};
			},
			// Opens provider
			openComic(url) {
				window.open(url);
			},
			preload(url) {
				fetch(url, {
					credentials: "same-origin",
				})
					.then((result) => {
						if (result.status >= 200 && result.status <= 299) {
							return result.json();
						}
					})
					.then(async (result) => {
						if (result) {
							this.showError = false;
							if (result.images) {
								this.cache = { url: url };
								const images = [];
								for (var i = 0; i < result.images.length; i++) {
									const img = new Image();
									img.src = result.images[i].url;
									images.push(img.src);
								}
								this.cache.images = images;
							}
						}
					});
			},

			// Gets Images/Text of comic chapter
			fetchContent(url) {
				fetch(url, {
					credentials: "same-origin",
				})
					.then((result) => {
						if (result.status >= 200 && result.status <= 299) {
							return result.json();
						} else {
							throw Error(result.statusText);
						}
					})
					.then(async (i) => {
						if (i) {
							this.showError = false;
							if (i.images) {
								this.images = i.images;
								this.lastImageIndex = 0;
								this.intervals.forEach((interval) => {
									clearInterval(interval);
								});
								this.intervals = [];
								for (let x = 0; x < i.images.length; x++) {
									const img = await this.waitForRef("img-" + x);
									const targetUrl = this.cache ? this.cache.images[x] : i.images[x].url;
									img[0].src = targetUrl;
									img[0].style.display = "none";
									img[0].onload = async () => {
										const waitForLoad = setInterval(() => {
											if (this.lastImageIndex == x) {
												if (!img[0]) {
													clearInterval(waitForLoad);
													return;
												}
												img[0].style.display = "block";
												this.lastImageIndex += 1;
												if (this.lastImageIndex == i.images.length) this.showLoader = false;
												clearInterval(waitForLoad);
											}
										}, 10);
										this.intervals.push(waitForLoad);
									};
									img[0].onerror = () => {
										if (!img[0]) {
											return;
										}
										this.lastImageIndex++;
										img[0].style.display = "block";
										this.showErrorBox = true;
										window.setTimeout(() => {
											this.showErrorBox = false;
										}, 10000);
										throw "Image could not be loaded";
									};
								}
								if (this.cache) {
									this.cache = null;
								}
							}
							if (i.text) {
								this.text = i.text;
								this.showLoader = false;
							}
						} else if (!i || (this.images.length == 0 && i.images) || (this.text.length == 0 && i.text)) {
							this.showError = true;
							this.showLoader = false;
						}
					})
					.catch((err) => {
						this.showErrorBox = true;
						window.setTimeout(() => {
							this.showErrorBox = false;
						}, 2000);
						this.showError = true;
					});
			},
			// For getting next/prev chapter
			async change(c, preload = false) {
				let url = this.url.split("/database/showNovel?&url=")[1];
				if (!url) url = this.url.split("/database/showComic?&url=")[1];
				let newUrl = "";
				let chapter = "";
				if (this.comic.chapters) {
					const chapterIndex = this.comic.chapters.findIndex((ch) => ch.chapter == this.comic.chapter);
					if (chapterIndex != -1 && chapterIndex + c < this.comic.chapters.length && chapterIndex + c >= 0) {
						{
							chapter = this.comic.chapters[chapterIndex + c];
							newUrl = chapter.url;
						}
					}
				}
				/* else if (newUrl == "") {
					for (var i = url.length - 1; i >= 0; i--) {
						const char = url[i];
						const result = parseInt(char);

						if (!isNaN(result)) {
							chapter = char + chapter;
						}
						if (isNaN(result) && i != url.length - 1) {
							break;
						}
					}
					chapter = parseInt(chapter) + c;
					newUrl = url.substring(0, i + 1) + chapter;
				} */
				if (!preload) {
					for (let i = 0; i < this.images.length; i++) {
						const img = await this.waitForRef("img-" + i);
						img[0].style.display = "none";
						img[0].onload = null;
						img[0].onerror = null;
					}
					this.$root.bus.$emit("readComic", this.comic, chapter);
				} else {
					if (this.comic.ln) {
						newUrl = `/database/showNovel?&url=${newUrl}`;
					} else {
						newUrl = `/database/showComic?&url=${newUrl}`;
					}
					this.preload(newUrl);
				}
			},
		},
	};
</script>
