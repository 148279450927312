<template>
<v-card v-if="comic" style="text-align:center">
    <v-list>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title style="white-space: break-spaces !important;cursor: pointer;" @click="openComicBrowser(comic.url)">{{
                              comic.title
                            }}</v-list-item-title>
                <v-list-item-subtitle class="mt-1" v-if="comic.chapters && comic.chapters.length > 0">{{ comic.chapter }}/{{
                                comic.chapters[comic.chapters.length-1].chapter
                              }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="!comic.chapters || comic.chapters.length == 0">{{
                              comic.chapter
                            }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
    <v-btn color="primary" text @click="openComic(comic)" style="font-size:25px">
        Read
    </v-btn>
    <v-card-text>
        <v-container v-if="chapters && chapters.length > 0" ref="chapterContainer" class="chapters" @scroll="addChapterButtons">
            <v-btn :ref="'btn-'+chapter.chapter" :class="{highlighted:chapter.chapter==comic.chapter}" style="width:95%;margin-top:5px;margin-left:5px;font-size:10px;" v-for="chapter in chapters" :key="chapter.url" @click="openComic(comic,chapter.url)">
                Chapter {{chapter.chapter}}
            </v-btn>
        </v-container>
    </v-card-text>
    <v-card-actions class="justify-center">
        <v-btn icon>
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-spacer>
            <v-btn icon @click="share(comic)">
                <v-icon>mdi-export-variant</v-icon>
            </v-btn>
        </v-spacer>
        <v-btn icon @click="deleteComic(comic.id)">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
    </v-card-actions>
</v-card>
</template>

<style scoped>
.highlighted {
    border: 2px solid white;
}

.chapters {
    position: relative;
    padding: 0;
    padding-bottom: 5px;
    background-color: #2196f3;
    width: 75%;
    overflow-y: scroll;
    max-height: 100px;
    border-radius: 4px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1e1e1e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>

<script>
export default {
    data() {
        return {
            loading: false,
            chapters: [],
        }
    },
    props: ['comic'],
    mounted() {
        this.scrollTo(this.comic.chapter)

    },
    watch: {
        comic: {
            immediate: true,

            handler(a, b) {

                this.chapters = [];
                if (this.comic.chapters) {
                    const chapterIndex = this.comic.chapters.findIndex((ch) => ch.chapter == a.chapter);
                    if (chapterIndex == -1)
                        return;

                    let firstButtonIndex = chapterIndex - 30;
                    let lastButtonIndex = chapterIndex + 30;
                    if (firstButtonIndex < 0)
                        firstButtonIndex = 0;
                    if (lastButtonIndex >= this.comic.chapters.length)
                        lastButtonIndex = this.comic.chapters.length - 1;

                    for (let i = firstButtonIndex; i <= lastButtonIndex; i++) {
                        this.chapters.push(this.comic.chapters[i])
                    }
                    this.scrollTo(a.chapter);
                }
            }
        }
    },
    methods: {
        addChapterButtons(e) {

            const element = e.target;
            if (element.scrollHeight - element.scrollTop == element.clientHeight) {
                if (this.chapters.length == 0)
                    return;
                const chapterIndex = this.comic.chapters.findIndex((ch) => ch.chapter == this.chapters[this.chapters.length - 1].chapter);
                if (chapterIndex == -1 || chapterIndex - 1 == -1)
                    return;
                let addButtonsToIndex = chapterIndex + 30;
                if (addButtonsToIndex >= this.comic.chapters.length)
                    addButtonsToIndex = this.comic.chapters.length-1;
                for (let i = chapterIndex+1; i <= addButtonsToIndex; i++) {
                    if (i < this.comic.chapters.length) {
                        this.chapters.push(this.comic.chapters[i])
                    } else {
                        break;
                    }
                }
            } else if (element.scrollTop == 0) {
                if (this.chapters.length == 0)
                    return;
                const chapterIndex = this.comic.chapters.findIndex((ch) => ch.chapter == this.chapters[0].chapter);
                if (chapterIndex == -1 || chapterIndex - 1 == -1)
                    return;
                let addButtonsToIndex = chapterIndex - 30;
                if (addButtonsToIndex < 0)
                    addButtonsToIndex = 0;
                for (let i = chapterIndex - 1; i >= addButtonsToIndex; i--) {
                    this.chapters.unshift(this.comic.chapters[i])
                }
                const oldChapter = this.comic.chapters[chapterIndex].chapter;
                this.waitForRef('btn-' + oldChapter)
                    .then((ref) => {
                        this.$refs.chapterContainer.scrollTop = ref[0].$el.offsetTop
                    })

            }
        },
        openComicBrowser(url) {
            window.open(url)
        },
        openComic(comic, url) {
            this.$root.bus.$emit("readComic", comic, url);
            this.$root.bus.$emit("closeComicInfo");
        },
        deleteComic(id) {
            this.$root.bus.$emit("deleteComic", id);
        },
        share(comic) {
            this.$root.bus.$emit("shareComic", comic);
        },
        scrollTo(ch) {

            const waitForButton = (time) => {
                const btn = this.$refs['btn-' + ch];
                if (!btn || !btn[0] || !btn[0].$el || btn[0].$el.offsetTop == 0) {
                    setTimeout(() => {
                        if (Date.now() - time < 2000)
                            waitForButton(time);
                    }, 10)
                } else {
                    this.$refs.chapterContainer.scrollTop = btn[0].$el.offsetTop - 32.5
                    return;
                }
            };
            waitForButton(Date.now());

        },
        waitForRef(ref, time = Date.now()) {
            return new Promise((res, rej) => {
                const r = this.$refs[ref];
                if (!r) {
                    if (Date.now() - time < 2000) {
                        setTimeout(() => {
                            return res(this.waitForRef(ref, time));
                        }, 10)
                    } else {
                        return rej(-1)
                    }

                } else {
                    return res(r)
                }
            })
        },
    },
};
</script>
