import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import login from "./components/login"
import addComic from "./components/addComic"
import wantToAddComic from "./components/wantToAddComic"
import loader from "./components/loader"
import comicInfo from "./components/comicInfo"
import comics from "./components/comics"
import MugenScroll from 'vue-mugen-scroll'
Vue.config.productionTip = false

Vue.component("comics",comics)
Vue.component("loader",loader)
Vue.component("login",login)
Vue.component("addComic",addComic)
Vue.component("wantToAddComic",wantToAddComic)
Vue.component("comicInfo",comicInfo)
Vue.component("mugen-scroll",MugenScroll)
new Vue({
  router,
  store,
  vuetify,
  mounted() {
    if(window.localStorage.getItem('loader'))
      this.showAllComics = (window.localStorage.getItem('loader') == 'true')
  },
  data:{
    comics: [],
    showAllComics: true,
    bus:new Vue()
  },
  watch:{
    showAllComics(val){
      window.localStorage.setItem('loader', val)
  }
  },
  render: h => h(App),
}).$mount('#app')
  
