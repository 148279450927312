<template>
	<router-view />
</template>

<style>
	html {
		overflow: hidden !important;
		/* min-height: 100vh;
		height: -webkit-fill-available; */
	}
	body {
		/* min-height: 100vh;
		min-height: -webkit-fill-available; */
	}
	.v-btn:before {
		background-color: transparent !important;
	}

	.v-calendar-daily__intervals-head,
	.v-calendar-daily__intervals-body,
	.v-calendar-daily__body {
		display: none !important;
	}

	.v-calendar .v-event {
		white-space: normal !important;
		height: fit-content !important;
		margin-bottom: 3px !important;
	}

	.v-calendar-daily_head-day {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.theme--dark.v-calendar-daily {
		border: none !important;
	}

	.theme--dark.v-calendar-daily .v-calendar-daily_head-day {
		border: none !important;
	}

	.v-badge__badge {
		inset: auto auto calc(100% - 35px) calc(100% - 35px) !important;
		cursor: pointer;
		border-bottom-left-radius: 10px !important;
		height: 25px !important;
		min-width: 25px !important;
		font-size: 14px !important;
		line-height: 1.2 !important;
		position: initial !important;
		box-shadow: 2px 3px 3px 0px rgb(0 0 0 / 50%) !important;
		transform-origin: right;
		transition: none !important;
	}

	.v-badge__wrapper {
		top: 10px !important;
		left: -10px !important;
		display: flex !important;
		justify-content: flex-end !important;
		overflow: hidden;
	}

	.container .row {
		justify-content: center !important;
	}

	.container .row .flex {
		flex: none !important;
	}

	::-webkit-scrollbar {
		width: 1vh;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: rgb(39 39 39);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #2196f3;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #5ca7e3;
	}

	.v-image__image,
	.v-image__placeholder {
		transition: 0.25s filter ease-in-out, 0.25s ease-in-out;
	}

	@keyframes expandLeft {
		0% {
			transform: translateX(100%);
		}

		100% {
			transform: translateX(0);
		}
	}

	@keyframes expandBottom {
		0% {
			transform: translateY(-100%);
		}

		100% {
			transform: translateY(0);
		}
	}

	.expandLeft-transition-enter-active {
		animation: expandLeft 0.2s ease-in-out;
	}

	.expandLeft-transition-leave-active {
		animation: expandLeft 0.2s reverse ease-in-out;
	}

	.expandBottom-transition-enter-active {
		animation: expandBottom 0.3s ease-in-out;
	}

	.expandBottom-transition-leave-active {
		animation: expandBottom 0.3s reverse ease-in-out;
	}
</style>

<style scoped>

</style>

<script>
	import { mapState, mapActions } from "vuex";
	export default {
		data: () => ({
			//
		}),
		async mounted() {
			const result = await this.getUser();
			if (!result) {
				console.log("Could not fetch user");
				this.$router.push({
					name: "Start",
				});
			}
		},
		computed: {
			...mapState(["user"]),
		},
		methods: {
			...mapActions(["getUser"]),
		},
	};
</script>
