<template>
	<div class="comic-preview-container">
		<div v-for="(comic, i) in comics" :key="comic.id">
			<v-badge
				overlap
				:content="
					comic.chapters && comic.chapters.length > 0
						? +(comic.chapters[comic.chapters.length - 1].chapter - comic.chapter).toFixed(2)
						: '/'
				"
				tile
				:value="
					(!currentComic || currentComic.id != comic.id) &&
						comic.chapters &&
						comic.chapters.length > 0 &&
						comic.chapters[comic.chapters.length - 1].chapter != comic.chapter
				"
				transition="expandLeft-transition"
			>
				<v-card class="comicCard" min-width="200">
					<v-img
						:ref="'img-' + i"
						eager
						@click="(e) => openComicInfo(e, comic, i)"
						:alt="comic.title"
						:src="comic.image"
						dark
						:aspect-ratio="2 / 3"
						style="cursor: pointer"
					>
						<v-slide-y-transition>
							<v-container class="comicCardContent" v-if="currentComic && currentComic.id == comic.id">
								<v-row
									style="height:20%;justify-content:center;display: flex;flex-direction: column;flex-wrap: nowrap;"
								>
									<v-tooltip bottom max-width="200" open-delay="400" color="#1e1e1e" transition="none">
										<template v-slot:activator="{ on, attrs }">
											<div class="comicCardTitle" @click="openComicBrowser(comic.url)">
												<span v-bind="attrs" v-on="on">{{ comic.title }}</span>
											</div>
										</template>
										<span>{{ comic.title }}</span>
									</v-tooltip>
									<div class="comicCardSubtitle">
										{{ comic.chapter
										}}<span v-if="comic.chapters && comic.chapters.length > 0"
											>/{{ comic.chapters[comic.chapters.length - 1].chapter }}</span
										>
									</div>
								</v-row>
								<v-row style="height:70%;">
									<div class="comicCardCenter">
										<v-btn color="primary" text style="font-size:30px;margin-top: 5%;margin-bottom: 5%;">
											Read
										</v-btn>
										<v-container
											v-if="comic.chapters && comic.chapters.length > 0"
											ref="chapterContainer"
											class="chapters"
											@scroll="addChapterButtons"
										>
											<v-btn
												:ref="'comic-' + comic.id + '-btn-' + chapter.chapter"
												:class="{ highlighted: chapter.chapter == comic.chapter }"
												style="width:95%;margin-top:5px;margin-left:5px;font-size:10px;"
												v-for="chapter in chapters"
												:key="chapter.url"
											>
												Chapter {{ chapter.chapter }}
											</v-btn>
											<v-btn
												style="width:95%;margin-top:5px;margin-left:5px;font-size:10px;"
												disabled
												v-if="getUploadFreq(comic)"
											>
												{{ getUploadFreq(comic).nextDay.toLocaleDateString() }}
											</v-btn>
										</v-container>
									</div>
								</v-row>
								<v-row style="height:10%;align-items: flex-end;margin-top:10%">
									<div class="justify-center comicCardActions">
										<v-btn icon @click="archive(comic.id)">
											<v-icon v-if="!comic.archive">mdi-archive</v-icon>
											<v-icon v-if="comic.archive"> mdi-archive-arrow-up</v-icon>
										</v-btn>
										<v-spacer style="display:flex;justify-content: center;">
											<v-btn icon @click="share(comic)" disabled>
												<v-icon>mdi-export-variant</v-icon>
											</v-btn>
										</v-spacer>
										<v-btn icon @click="deleteComic(comic.id)">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</div>
								</v-row>
							</v-container>
						</v-slide-y-transition>
					</v-img>
				</v-card>
			</v-badge>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				currentComic: null,
				chapters: [],
				comics: [
					{
						title: "Return of the Mount Hua Sect",
						chapter: "38",
						description:
							"Chung Myung, The 13th Disciple of the Mount Hua Sect, One of the 3 Great Swordsmen, Plum Blossom Sword Saint, defeated Chun Ma, who has brought destruction and disarray onto...",
						image: "https://www.asurascans.com/wp-content/uploads/2021/03/3.gif",
						url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-51/",
						mainURL: "https://www.asurascans.com/comics/return-of-the-mount-hua-sect/",
						chapters: [
							{
								chapter: "1",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-1/",
								date: "March 23, 2021",
							},
							{
								chapter: "2",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-2/",
								date: "March 25, 2021",
							},
							{
								chapter: "3",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-3/",
								date: "March 26, 2021",
							},
							{
								chapter: "4",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-4/",
								date: "March 28, 2021",
							},
							{
								chapter: "5",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-5/",
								date: "March 29, 2021",
							},
							{
								chapter: "6",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-6/",
								date: "April 3, 2021",
							},
							{
								chapter: "7",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-7/",
								date: "April 10, 2021",
							},
							{
								chapter: "8",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-8/",
								date: "April 16, 2021",
							},
							{
								chapter: "9",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-9/",
								date: "April 24, 2021",
							},
							{
								chapter: "10",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-10/",
								date: "May 4, 2021",
							},
							{
								chapter: "11",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-11/",
								date: "May 14, 2021",
							},
							{
								chapter: "12",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-12/",
								date: "May 14, 2021",
							},
							{
								chapter: "13",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-13/",
								date: "May 28, 2021",
							},
							{
								chapter: "14",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-14/",
								date: "May 30, 2021",
							},
							{
								chapter: "15",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-15/",
								date: "June 7, 2021",
							},
							{
								chapter: "16",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-16/",
								date: "June 10, 2021",
							},
							{
								chapter: "17",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-17/",
								date: "June 19, 2021",
							},
							{
								chapter: "18",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-18/",
								date: "June 25, 2021",
							},
							{
								chapter: "19",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-19/",
								date: "July 5, 2021",
							},
							{
								chapter: "20",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-20/",
								date: "July 13, 2021",
							},
							{
								chapter: "21",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-21/",
								date: "July 17, 2021",
							},
							{
								chapter: "22",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-22/",
								date: "August 1, 2021",
							},
							{
								chapter: "23",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-23/",
								date: "August 2, 2021",
							},
							{
								chapter: "24",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-24/",
								date: "August 10, 2021",
							},
							{
								chapter: "25",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-25/",
								date: "August 21, 2021",
							},
							{
								chapter: "26",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-26/",
								date: "August 21, 2021",
							},
							{
								chapter: "27",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-27/",
								date: "August 31, 2021",
							},
							{
								chapter: "28",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-28/",
								date: "September 26, 2021",
							},
							{
								chapter: "29",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-29/",
								date: "September 26, 2021",
							},
							{
								chapter: "30",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-30/",
								date: "October 5, 2021",
							},
							{
								chapter: "31",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-31/",
								date: "October 5, 2021",
							},
							{
								chapter: "32",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-32/",
								date: "October 6, 2021",
							},
							{
								chapter: "33",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-33/",
								date: "October 13, 2021",
							},
							{
								chapter: "34",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-34/",
								date: "October 19, 2021",
							},
							{
								chapter: "35",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-35/",
								date: "October 25, 2021",
							},
							{
								chapter: "36",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-36/",
								date: "November 2, 2021",
							},
							{
								chapter: "37",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-37/",
								date: "November 16, 2021",
							},
							{
								chapter: "38",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-38/",
								date: "November 20, 2021",
							},
							{
								chapter: "39",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-39/",
								date: "November 29, 2021",
							},
							{
								chapter: "40",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-40/",
								date: "December 1, 2021",
							},
							{
								chapter: "41",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-41/",
								date: "December 3, 2021",
							},
							{
								chapter: "42",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-42/",
								date: "December 14, 2021",
							},
							{
								chapter: "43",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-43/",
								date: "December 14, 2021",
							},
							{
								chapter: "44",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-44/",
								date: "December 24, 2021",
							},
							{
								chapter: "45",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-45/",
								date: "December 30, 2021",
							},
							{
								chapter: "46",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-46/",
								date: "January 8, 2022",
							},
							{
								chapter: "47",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-47/",
								date: "January 16, 2022",
							},
							{
								chapter: "48",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-48/",
								date: "January 25, 2022",
							},
							{
								chapter: "49",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-49/",
								date: "January 27, 2022",
							},
							{
								chapter: "50",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-50/",
								date: "February 5, 2022",
							},
							{
								chapter: "51",
								url: "https://www.asurascans.com/return-of-the-mount-hua-sect-chapter-51/",
								date: "February 12, 2022",
							},
						],
						ln: false,
						id: 1630866890878,
						archive: false,
						selected: false,
					},
					{
						title: "Regina Rena",
						chapter: "11",
						description:
							'"I\'ll give you a chance. A chance to be forgiven by me."The father sold his daughter. And the daughter came back from hell. In the empire mixed with life and death, the young Rena Ruber was destined to die pitifully as an offering for her father. But 6 years later, the girl that everyone thought had died returned. Taking off her mask of a lamb, and emerging as a lion.',
						image: "https://flamescans.org/wp-content/uploads/2021/12/regina_rina-copy-1070x1410.png",
						url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-11/",
						mainURL: "https://flamescans.org/series/regina-rena-to-the-unforgivable/",
						chapters: [
							{
								chapter: "1",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-1/",
								date: "December 20, 2021",
							},
							{
								chapter: "2",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-2/",
								date: "December 20, 2021",
							},
							{
								chapter: "3",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-3/",
								date: "December 20, 2021",
							},
							{
								chapter: "4",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-4/",
								date: "December 20, 2021",
							},
							{
								chapter: "5",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-5/",
								date: "December 20, 2021",
							},
							{
								chapter: "6",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-6/",
								date: "December 20, 2021",
							},
							{
								chapter: "7",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-7/",
								date: "December 21, 2021",
							},
							{
								chapter: "8",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-8/",
								date: "December 27, 2021",
							},
							{
								chapter: "9",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-9/",
								date: "January 6, 2022",
							},
							{
								chapter: "10",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-10/",
								date: "January 12, 2022",
							},
							{
								chapter: "11",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-11/",
								date: "January 22, 2022",
							},
							{
								chapter: "12",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-12/",
								date: "January 30, 2022",
							},
							{
								chapter: "13",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-13/",
								date: "February 5, 2022",
							},
							{
								chapter: "14",
								url: "https://flamescans.org/regina-rena-to-the-unforgivable-chapter-14/",
								date: "February 12, 2022",
							},
						],
						ln: false,
						id: 1643955246183,
						archive: false,
						selected: false,
					},
					{
						title: "Peerless Dad",
						chapter: "203",
						description:
							"Peerless Dad : Its an amazing story about one martial arts journey through marriage, fatherhood, and battles.",
						image: "https://avt.mkklcdnv6temp.com/39/j/16-1583494878.jpg",
						url: "https://readmanganato.com/manga-cz979382/chapter-203",
						mainURL: "https://readmanganato.com/manga-cz979382/",
						chapters: [
							{ chapter: "0", url: "https://readmanganato.com/manga-cz979382/chapter-0", date: "Aug 25,19" },
							{ chapter: "1", url: "https://readmanganato.com/manga-cz979382/chapter-1", date: "Aug 25,19" },
							{ chapter: "2", url: "https://readmanganato.com/manga-cz979382/chapter-2", date: "Aug 25,19" },
							{ chapter: "3", url: "https://readmanganato.com/manga-cz979382/chapter-3", date: "Aug 25,19" },
							{ chapter: "4", url: "https://readmanganato.com/manga-cz979382/chapter-4", date: "Aug 25,19" },
							{ chapter: "5", url: "https://readmanganato.com/manga-cz979382/chapter-5", date: "Aug 25,19" },
							{ chapter: "6", url: "https://readmanganato.com/manga-cz979382/chapter-6", date: "Aug 25,19" },
							{ chapter: "7", url: "https://readmanganato.com/manga-cz979382/chapter-7", date: "Aug 25,19" },
							{ chapter: "8", url: "https://readmanganato.com/manga-cz979382/chapter-8", date: "Aug 25,19" },
							{ chapter: "9", url: "https://readmanganato.com/manga-cz979382/chapter-9", date: "Aug 25,19" },
							{ chapter: "10", url: "https://readmanganato.com/manga-cz979382/chapter-10", date: "Aug 25,19" },
							{ chapter: "11", url: "https://readmanganato.com/manga-cz979382/chapter-11", date: "Aug 25,19" },
							{ chapter: "12", url: "https://readmanganato.com/manga-cz979382/chapter-12", date: "Aug 25,19" },
							{ chapter: "13", url: "https://readmanganato.com/manga-cz979382/chapter-13", date: "Aug 25,19" },
							{ chapter: "14", url: "https://readmanganato.com/manga-cz979382/chapter-14", date: "Aug 25,19" },
							{ chapter: "15", url: "https://readmanganato.com/manga-cz979382/chapter-15", date: "Aug 25,19" },
							{ chapter: "16", url: "https://readmanganato.com/manga-cz979382/chapter-16", date: "Aug 25,19" },
							{ chapter: "17", url: "https://readmanganato.com/manga-cz979382/chapter-17", date: "Aug 25,19" },
							{ chapter: "18", url: "https://readmanganato.com/manga-cz979382/chapter-18", date: "Aug 25,19" },
							{ chapter: "19", url: "https://readmanganato.com/manga-cz979382/chapter-19", date: "Aug 25,19" },
							{ chapter: "20", url: "https://readmanganato.com/manga-cz979382/chapter-20", date: "Aug 25,19" },
							{ chapter: "21", url: "https://readmanganato.com/manga-cz979382/chapter-21", date: "Aug 25,19" },
							{ chapter: "22", url: "https://readmanganato.com/manga-cz979382/chapter-22", date: "Aug 25,19" },
							{ chapter: "23", url: "https://readmanganato.com/manga-cz979382/chapter-23", date: "Aug 25,19" },
							{ chapter: "24", url: "https://readmanganato.com/manga-cz979382/chapter-24", date: "Aug 25,19" },
							{ chapter: "25", url: "https://readmanganato.com/manga-cz979382/chapter-25", date: "Aug 25,19" },
							{ chapter: "26", url: "https://readmanganato.com/manga-cz979382/chapter-26", date: "Aug 25,19" },
							{ chapter: "27", url: "https://readmanganato.com/manga-cz979382/chapter-27", date: "Aug 25,19" },
							{ chapter: "28", url: "https://readmanganato.com/manga-cz979382/chapter-28", date: "Aug 25,19" },
							{ chapter: "29", url: "https://readmanganato.com/manga-cz979382/chapter-29", date: "Aug 25,19" },
							{ chapter: "30", url: "https://readmanganato.com/manga-cz979382/chapter-30", date: "Aug 25,19" },
							{ chapter: "31", url: "https://readmanganato.com/manga-cz979382/chapter-31", date: "Aug 25,19" },
							{ chapter: "32", url: "https://readmanganato.com/manga-cz979382/chapter-32", date: "Aug 25,19" },
							{ chapter: "33", url: "https://readmanganato.com/manga-cz979382/chapter-33", date: "Aug 25,19" },
							{ chapter: "34", url: "https://readmanganato.com/manga-cz979382/chapter-34", date: "Aug 25,19" },
							{ chapter: "35", url: "https://readmanganato.com/manga-cz979382/chapter-35", date: "Aug 25,19" },
							{ chapter: "36", url: "https://readmanganato.com/manga-cz979382/chapter-36", date: "Aug 25,19" },
							{ chapter: "37", url: "https://readmanganato.com/manga-cz979382/chapter-37", date: "Aug 25,19" },
							{ chapter: "38", url: "https://readmanganato.com/manga-cz979382/chapter-38", date: "Aug 25,19" },
							{ chapter: "39", url: "https://readmanganato.com/manga-cz979382/chapter-39", date: "Aug 25,19" },
							{ chapter: "40", url: "https://readmanganato.com/manga-cz979382/chapter-40", date: "Aug 25,19" },
							{ chapter: "41", url: "https://readmanganato.com/manga-cz979382/chapter-41", date: "Aug 25,19" },
							{ chapter: "42", url: "https://readmanganato.com/manga-cz979382/chapter-42", date: "Aug 25,19" },
							{ chapter: "43", url: "https://readmanganato.com/manga-cz979382/chapter-43", date: "Aug 25,19" },
							{ chapter: "44", url: "https://readmanganato.com/manga-cz979382/chapter-44", date: "Aug 25,19" },
							{ chapter: "45", url: "https://readmanganato.com/manga-cz979382/chapter-45", date: "Aug 25,19" },
							{ chapter: "46", url: "https://readmanganato.com/manga-cz979382/chapter-46", date: "Aug 25,19" },
							{ chapter: "47", url: "https://readmanganato.com/manga-cz979382/chapter-47", date: "Aug 25,19" },
							{ chapter: "48", url: "https://readmanganato.com/manga-cz979382/chapter-48", date: "Aug 25,19" },
							{ chapter: "49", url: "https://readmanganato.com/manga-cz979382/chapter-49", date: "Aug 25,19" },
							{ chapter: "50", url: "https://readmanganato.com/manga-cz979382/chapter-50", date: "Aug 25,19" },
							{ chapter: "50.5", url: "https://readmanganato.com/manga-cz979382/chapter-50.5", date: "Aug 25,19" },
							{ chapter: "51", url: "https://readmanganato.com/manga-cz979382/chapter-51", date: "Aug 25,19" },
							{ chapter: "52", url: "https://readmanganato.com/manga-cz979382/chapter-52", date: "Aug 25,19" },
							{ chapter: "53", url: "https://readmanganato.com/manga-cz979382/chapter-53", date: "Aug 25,19" },
							{ chapter: "54", url: "https://readmanganato.com/manga-cz979382/chapter-54", date: "Aug 25,19" },
							{ chapter: "55", url: "https://readmanganato.com/manga-cz979382/chapter-55", date: "Aug 25,19" },
							{ chapter: "56", url: "https://readmanganato.com/manga-cz979382/chapter-56", date: "Aug 25,19" },
							{ chapter: "57", url: "https://readmanganato.com/manga-cz979382/chapter-57", date: "Aug 25,19" },
							{ chapter: "58", url: "https://readmanganato.com/manga-cz979382/chapter-58", date: "Aug 25,19" },
							{ chapter: "59", url: "https://readmanganato.com/manga-cz979382/chapter-59", date: "Aug 25,19" },
							{ chapter: "60", url: "https://readmanganato.com/manga-cz979382/chapter-60", date: "Aug 25,19" },
							{ chapter: "61", url: "https://readmanganato.com/manga-cz979382/chapter-61", date: "Aug 25,19" },
							{ chapter: "62", url: "https://readmanganato.com/manga-cz979382/chapter-62", date: "Aug 25,19" },
							{ chapter: "63", url: "https://readmanganato.com/manga-cz979382/chapter-63", date: "Aug 25,19" },
							{ chapter: "64", url: "https://readmanganato.com/manga-cz979382/chapter-64", date: "Aug 25,19" },
							{ chapter: "65", url: "https://readmanganato.com/manga-cz979382/chapter-65", date: "Aug 25,19" },
							{ chapter: "66", url: "https://readmanganato.com/manga-cz979382/chapter-66", date: "Aug 25,19" },
							{ chapter: "67", url: "https://readmanganato.com/manga-cz979382/chapter-67", date: "Aug 25,19" },
							{ chapter: "68", url: "https://readmanganato.com/manga-cz979382/chapter-68", date: "Aug 25,19" },
							{ chapter: "69", url: "https://readmanganato.com/manga-cz979382/chapter-69", date: "Aug 25,19" },
							{ chapter: "70", url: "https://readmanganato.com/manga-cz979382/chapter-70", date: "Aug 25,19" },
							{ chapter: "71", url: "https://readmanganato.com/manga-cz979382/chapter-71", date: "Aug 25,19" },
							{ chapter: "72", url: "https://readmanganato.com/manga-cz979382/chapter-72", date: "Aug 25,19" },
							{ chapter: "73", url: "https://readmanganato.com/manga-cz979382/chapter-73", date: "Aug 25,19" },
							{ chapter: "74", url: "https://readmanganato.com/manga-cz979382/chapter-74", date: "Aug 25,19" },
							{ chapter: "75", url: "https://readmanganato.com/manga-cz979382/chapter-75", date: "Aug 25,19" },
							{ chapter: "76", url: "https://readmanganato.com/manga-cz979382/chapter-76", date: "Aug 25,19" },
							{ chapter: "77", url: "https://readmanganato.com/manga-cz979382/chapter-77", date: "Aug 25,19" },
							{ chapter: "78", url: "https://readmanganato.com/manga-cz979382/chapter-78", date: "Aug 25,19" },
							{ chapter: "79", url: "https://readmanganato.com/manga-cz979382/chapter-79", date: "Aug 25,19" },
							{ chapter: "80", url: "https://readmanganato.com/manga-cz979382/chapter-80", date: "Aug 25,19" },
							{ chapter: "81", url: "https://readmanganato.com/manga-cz979382/chapter-81", date: "Aug 25,19" },
							{ chapter: "82", url: "https://readmanganato.com/manga-cz979382/chapter-82", date: "Aug 25,19" },
							{ chapter: "83", url: "https://readmanganato.com/manga-cz979382/chapter-83", date: "Aug 25,19" },
							{ chapter: "84", url: "https://readmanganato.com/manga-cz979382/chapter-84", date: "Aug 25,19" },
							{ chapter: "85", url: "https://readmanganato.com/manga-cz979382/chapter-85", date: "Aug 27,19" },
							{ chapter: "86", url: "https://readmanganato.com/manga-cz979382/chapter-86", date: "Aug 27,19" },
							{ chapter: "87", url: "https://readmanganato.com/manga-cz979382/chapter-87", date: "Sep 03,19" },
							{ chapter: "88", url: "https://readmanganato.com/manga-cz979382/chapter-88", date: "Sep 05,19" },
							{ chapter: "89", url: "https://readmanganato.com/manga-cz979382/chapter-89", date: "Sep 15,19" },
							{ chapter: "90", url: "https://readmanganato.com/manga-cz979382/chapter-90", date: "Sep 19,19" },
							{ chapter: "91", url: "https://readmanganato.com/manga-cz979382/chapter-91", date: "Sep 27,19" },
							{ chapter: "92", url: "https://readmanganato.com/manga-cz979382/chapter-92", date: "Oct 05,19" },
							{ chapter: "93", url: "https://readmanganato.com/manga-cz979382/chapter-93", date: "Nov 01,19" },
							{ chapter: "94", url: "https://readmanganato.com/manga-cz979382/chapter-94", date: "Nov 01,19" },
							{ chapter: "95", url: "https://readmanganato.com/manga-cz979382/chapter-95", date: "Nov 01,19" },
							{ chapter: "96", url: "https://readmanganato.com/manga-cz979382/chapter-96", date: "Nov 01,19" },
							{ chapter: "97", url: "https://readmanganato.com/manga-cz979382/chapter-97", date: "Dec 03,19" },
							{ chapter: "98", url: "https://readmanganato.com/manga-cz979382/chapter-98", date: "Dec 03,19" },
							{ chapter: "99", url: "https://readmanganato.com/manga-cz979382/chapter-99", date: "Dec 03,19" },
							{ chapter: "100", url: "https://readmanganato.com/manga-cz979382/chapter-100", date: "Dec 03,19" },
							{ chapter: "101", url: "https://readmanganato.com/manga-cz979382/chapter-101", date: "Dec 03,19" },
							{ chapter: "102", url: "https://readmanganato.com/manga-cz979382/chapter-102", date: "Dec 17,19" },
							{ chapter: "103", url: "https://readmanganato.com/manga-cz979382/chapter-103", date: "Dec 24,19" },
							{ chapter: "104", url: "https://readmanganato.com/manga-cz979382/chapter-104", date: "Dec 31,19" },
							{ chapter: "105", url: "https://readmanganato.com/manga-cz979382/chapter-105", date: "Jan 07,20" },
							{ chapter: "106", url: "https://readmanganato.com/manga-cz979382/chapter-106", date: "Jan 14,20" },
							{ chapter: "107", url: "https://readmanganato.com/manga-cz979382/chapter-107", date: "Jan 21,20" },
							{ chapter: "108", url: "https://readmanganato.com/manga-cz979382/chapter-108", date: "Feb 04,20" },
							{ chapter: "109", url: "https://readmanganato.com/manga-cz979382/chapter-109", date: "Feb 11,20" },
							{ chapter: "110", url: "https://readmanganato.com/manga-cz979382/chapter-110", date: "Feb 18,20" },
							{ chapter: "111", url: "https://readmanganato.com/manga-cz979382/chapter-111", date: "Feb 25,20" },
							{ chapter: "112", url: "https://readmanganato.com/manga-cz979382/chapter-112", date: "Mar 03,20" },
							{ chapter: "113", url: "https://readmanganato.com/manga-cz979382/chapter-113", date: "Mar 10,20" },
							{ chapter: "114", url: "https://readmanganato.com/manga-cz979382/chapter-114", date: "Mar 17,20" },
							{ chapter: "115", url: "https://readmanganato.com/manga-cz979382/chapter-115", date: "Mar 24,20" },
							{ chapter: "116", url: "https://readmanganato.com/manga-cz979382/chapter-116", date: "Mar 31,20" },
							{ chapter: "117", url: "https://readmanganato.com/manga-cz979382/chapter-117", date: "Apr 07,20" },
							{ chapter: "118", url: "https://readmanganato.com/manga-cz979382/chapter-118", date: "Apr 14,20" },
							{ chapter: "119", url: "https://readmanganato.com/manga-cz979382/chapter-119", date: "Apr 28,20" },
							{ chapter: "120", url: "https://readmanganato.com/manga-cz979382/chapter-120", date: "May 05,20" },
							{ chapter: "121", url: "https://readmanganato.com/manga-cz979382/chapter-121", date: "May 12,20" },
							{ chapter: "122", url: "https://readmanganato.com/manga-cz979382/chapter-122", date: "May 19,20" },
							{ chapter: "123", url: "https://readmanganato.com/manga-cz979382/chapter-123", date: "May 23,20" },
							{ chapter: "124", url: "https://readmanganato.com/manga-cz979382/chapter-124", date: "May 26,20" },
							{ chapter: "125", url: "https://readmanganato.com/manga-cz979382/chapter-125", date: "Jun 02,20" },
							{ chapter: "126", url: "https://readmanganato.com/manga-cz979382/chapter-126", date: "Jun 10,20" },
							{ chapter: "127", url: "https://readmanganato.com/manga-cz979382/chapter-127", date: "Jun 16,20" },
							{ chapter: "128", url: "https://readmanganato.com/manga-cz979382/chapter-128", date: "Jun 23,20" },
							{ chapter: "129", url: "https://readmanganato.com/manga-cz979382/chapter-129", date: "Jun 30,20" },
							{ chapter: "130", url: "https://readmanganato.com/manga-cz979382/chapter-130", date: "Jul 07,20" },
							{ chapter: "131", url: "https://readmanganato.com/manga-cz979382/chapter-131", date: "Jul 14,20" },
							{ chapter: "132", url: "https://readmanganato.com/manga-cz979382/chapter-132", date: "Jul 21,20" },
							{ chapter: "133", url: "https://readmanganato.com/manga-cz979382/chapter-133", date: "Jul 28,20" },
							{ chapter: "134", url: "https://readmanganato.com/manga-cz979382/chapter-134", date: "Aug 04,20" },
							{ chapter: "135", url: "https://readmanganato.com/manga-cz979382/chapter-135", date: "Aug 12,20" },
							{ chapter: "136", url: "https://readmanganato.com/manga-cz979382/chapter-136", date: "Aug 19,20" },
							{ chapter: "137", url: "https://readmanganato.com/manga-cz979382/chapter-137", date: "Aug 26,20" },
							{ chapter: "138", url: "https://readmanganato.com/manga-cz979382/chapter-138", date: "Sep 02,20" },
							{ chapter: "139", url: "https://readmanganato.com/manga-cz979382/chapter-139", date: "Sep 09,20" },
							{ chapter: "140", url: "https://readmanganato.com/manga-cz979382/chapter-140", date: "Sep 16,20" },
							{ chapter: "141", url: "https://readmanganato.com/manga-cz979382/chapter-141", date: "Sep 23,20" },
							{ chapter: "142", url: "https://readmanganato.com/manga-cz979382/chapter-142", date: "Sep 30,20" },
							{ chapter: "143", url: "https://readmanganato.com/manga-cz979382/chapter-143", date: "Oct 13,20" },
							{ chapter: "144", url: "https://readmanganato.com/manga-cz979382/chapter-144", date: "Oct 20,20" },
							{ chapter: "145", url: "https://readmanganato.com/manga-cz979382/chapter-145", date: "Oct 20,20" },
							{ chapter: "146", url: "https://readmanganato.com/manga-cz979382/chapter-146", date: "Oct 27,20" },
							{ chapter: "147", url: "https://readmanganato.com/manga-cz979382/chapter-147", date: "Nov 03,20" },
							{ chapter: "148", url: "https://readmanganato.com/manga-cz979382/chapter-148", date: "Nov 10,20" },
							{ chapter: "149", url: "https://readmanganato.com/manga-cz979382/chapter-149", date: "Nov 17,20" },
							{ chapter: "150", url: "https://readmanganato.com/manga-cz979382/chapter-150", date: "Nov 24,20" },
							{ chapter: "151", url: "https://readmanganato.com/manga-cz979382/chapter-151", date: "Dec 01,20" },
							{ chapter: "152", url: "https://readmanganato.com/manga-cz979382/chapter-152", date: "Dec 08,20" },
							{ chapter: "153", url: "https://readmanganato.com/manga-cz979382/chapter-153", date: "Dec 16,20" },
							{ chapter: "154", url: "https://readmanganato.com/manga-cz979382/chapter-154", date: "Dec 22,20" },
							{ chapter: "155", url: "https://readmanganato.com/manga-cz979382/chapter-155", date: "Jan 05,21" },
							{ chapter: "156", url: "https://readmanganato.com/manga-cz979382/chapter-156", date: "Jan 12,21" },
							{ chapter: "157", url: "https://readmanganato.com/manga-cz979382/chapter-157", date: "Jan 19,21" },
							{ chapter: "158", url: "https://readmanganato.com/manga-cz979382/chapter-158", date: "Jan 27,21" },
							{ chapter: "159", url: "https://readmanganato.com/manga-cz979382/chapter-159", date: "Feb 02,21" },
							{ chapter: "160", url: "https://readmanganato.com/manga-cz979382/chapter-160", date: "Feb 10,21" },
							{ chapter: "161", url: "https://readmanganato.com/manga-cz979382/chapter-161", date: "Feb 16,21" },
							{ chapter: "162", url: "https://readmanganato.com/manga-cz979382/chapter-162", date: "Feb 23,21" },
							{ chapter: "163", url: "https://readmanganato.com/manga-cz979382/chapter-163", date: "Mar 03,21" },
							{ chapter: "164", url: "https://readmanganato.com/manga-cz979382/chapter-164", date: "Mar 09,21" },
							{ chapter: "165", url: "https://readmanganato.com/manga-cz979382/chapter-165", date: "Mar 16,21" },
							{ chapter: "166", url: "https://readmanganato.com/manga-cz979382/chapter-166", date: "Mar 23,21" },
							{ chapter: "167", url: "https://readmanganato.com/manga-cz979382/chapter-167", date: "Jun 29,21" },
							{ chapter: "168", url: "https://readmanganato.com/manga-cz979382/chapter-168", date: "Jun 30,21" },
							{ chapter: "169", url: "https://readmanganato.com/manga-cz979382/chapter-169", date: "Jun 30,21" },
							{ chapter: "170", url: "https://readmanganato.com/manga-cz979382/chapter-170", date: "Jul 01,21" },
							{ chapter: "171", url: "https://readmanganato.com/manga-cz979382/chapter-171", date: "Jul 02,21" },
							{ chapter: "172", url: "https://readmanganato.com/manga-cz979382/chapter-172", date: "Jul 05,21" },
							{ chapter: "173", url: "https://readmanganato.com/manga-cz979382/chapter-173", date: "Jul 05,21" },
							{ chapter: "174", url: "https://readmanganato.com/manga-cz979382/chapter-174", date: "Jul 05,21" },
							{ chapter: "175", url: "https://readmanganato.com/manga-cz979382/chapter-175", date: "Jul 10,21" },
							{ chapter: "176", url: "https://readmanganato.com/manga-cz979382/chapter-176", date: "Jul 12,21" },
							{ chapter: "177", url: "https://readmanganato.com/manga-cz979382/chapter-177", date: "Jul 12,21" },
							{ chapter: "178", url: "https://readmanganato.com/manga-cz979382/chapter-178", date: "Jul 16,21" },
							{ chapter: "179", url: "https://readmanganato.com/manga-cz979382/chapter-179", date: "Jul 24,21" },
							{ chapter: "180", url: "https://readmanganato.com/manga-cz979382/chapter-180", date: "Aug 07,21" },
							{ chapter: "181", url: "https://readmanganato.com/manga-cz979382/chapter-181", date: "Aug 07,21" },
							{ chapter: "182", url: "https://readmanganato.com/manga-cz979382/chapter-182", date: "Aug 12,21" },
							{ chapter: "183", url: "https://readmanganato.com/manga-cz979382/chapter-183", date: "Aug 15,21" },
							{ chapter: "184", url: "https://readmanganato.com/manga-cz979382/chapter-184", date: "Aug 17,21" },
							{ chapter: "185", url: "https://readmanganato.com/manga-cz979382/chapter-185", date: "Sep 02,21" },
							{ chapter: "186", url: "https://readmanganato.com/manga-cz979382/chapter-186", date: "Sep 08,21" },
							{ chapter: "187", url: "https://readmanganato.com/manga-cz979382/chapter-187", date: "Sep 15,21" },
							{ chapter: "188", url: "https://readmanganato.com/manga-cz979382/chapter-188", date: "Sep 22,21" },
							{ chapter: "189", url: "https://readmanganato.com/manga-cz979382/chapter-189", date: "Sep 28,21" },
							{ chapter: "190", url: "https://readmanganato.com/manga-cz979382/chapter-190", date: "Oct 14,21" },
							{ chapter: "191", url: "https://readmanganato.com/manga-cz979382/chapter-191", date: "Oct 14,21" },
							{ chapter: "192", url: "https://readmanganato.com/manga-cz979382/chapter-192", date: "Oct 21,21" },
							{ chapter: "193", url: "https://readmanganato.com/manga-cz979382/chapter-193", date: "Oct 29,21" },
							{ chapter: "194", url: "https://readmanganato.com/manga-cz979382/chapter-194", date: "Nov 10,21" },
							{ chapter: "195", url: "https://readmanganato.com/manga-cz979382/chapter-195", date: "Nov 19,21" },
							{ chapter: "196", url: "https://readmanganato.com/manga-cz979382/chapter-196", date: "Nov 25,21" },
							{ chapter: "197", url: "https://readmanganato.com/manga-cz979382/chapter-197", date: "Dec 03,21" },
							{ chapter: "198", url: "https://readmanganato.com/manga-cz979382/chapter-198", date: "Dec 09,21" },
							{ chapter: "199", url: "https://readmanganato.com/manga-cz979382/chapter-199", date: "Dec 16,21" },
							{ chapter: "200", url: "https://readmanganato.com/manga-cz979382/chapter-200", date: "Dec 21,21" },
							{ chapter: "201", url: "https://readmanganato.com/manga-cz979382/chapter-201", date: "Dec 29,21" },
							{ chapter: "202", url: "https://readmanganato.com/manga-cz979382/chapter-202", date: "Jan 15,22" },
							{ chapter: "203", url: "https://readmanganato.com/manga-cz979382/chapter-203", date: "Jan 24,22" },
							{ chapter: "204", url: "https://readmanganato.com/manga-cz979382/chapter-204", date: "Feb 05,22" },
							{ chapter: "205", url: "https://readmanganato.com/manga-cz979382/chapter-205", date: "Feb 05,22" },
							{ chapter: "206", url: "https://readmanganato.com/manga-cz979382/chapter-206", date: "Feb 11,22" },
						],
						ln: false,
						id: 1641449959784,
						archive: false,
						selected: false,
					},
				],
			};
		},
		watch: {
			currentComic: {
				immediate: true,

				handler(a, b) {
					if (a) {
						this.chapters = [];
						if (this.currentComic.chapters) {
							const chapterIndex = this.currentComic.chapters.findIndex((ch) => ch.chapter == a.chapter);
							if (chapterIndex == -1) return;

							let firstButtonIndex = chapterIndex - 30;
							let lastButtonIndex = chapterIndex + 30;
							if (firstButtonIndex < 0) firstButtonIndex = 0;
							if (lastButtonIndex >= this.currentComic.chapters.length)
								lastButtonIndex = this.currentComic.chapters.length - 1;

							for (let i = firstButtonIndex; i <= lastButtonIndex; i++) {
								this.chapters.push(this.currentComic.chapters[i]);
							}
							this.scrollTo(this.currentComic, a.chapter);
						}
					} else {
						this.chapters = [];
					}
				},
			},
		},
		methods: {
			getUploadFreq(comic) {
				if (comic.chapters && comic.chapters.length > 0 && !comic.chapters[0].date) return;
				if (comic.chapters && comic.chapters.length > 1) {
					let i = 0;
					let a = null;
					let b = null;
					let aDate = null;
					let bDate = null;
					do {
						a = comic.chapters[comic.chapters.length - 2 - i];
						b = comic.chapters[comic.chapters.length - 1 - i];
						aDate = new Date(a.date);
						if (aDate == "Invalid Date") aDate = new Date();
						bDate = new Date(b.date);
						if (bDate == "Invalid Date") bDate = new Date();
						i += 1;
					} while (
						comic.chapters.length - 2 - i >= 0 &&
						(aDate == "Invalid Date" || bDate == "Invalid Date" || +aDate == +bDate)
					);
					if (comic.chapters.length - 2 - i < 0 || aDate == "Invalid Date" || bDate == "Invalid Date") return;
					const freq = bDate - aDate;
					return {
						freq: freq / 86400000,
						nextDay: new Date(+bDate + freq),
					};
				}
			},
			errorImage(comic) {
				if (comic.image.charAt(0) != "/") {
					comic.image = "/database/image?url=" + comic.image;
				}
			},
			closeComicInfo() {
				//this.menu = false;
				if (this.currentComic) {
					this.currentComic.selected = false;
					const i = this.comics.findIndex((c) => c.id == this.currentComic.id);
					if (
						this.$refs["img-" + i] &&
						this.$refs["img-" + i][0] &&
						this.$refs["img-" + i][0].$el &&
						this.$refs["img-" + i][0].$el.children[1]
					) {
						const img = this.$refs["img-" + i][0].$el.children[1];
						img.style.filter = "blur(0)";
						img.style.opacity = 1;
					}
					this.chapters = [];
					this.currentComic = null;
				}
			},
			openComicInfo(e, comic, i) {
				if (this.currentComic == comic) {
					this.closeComicInfo();
					return;
				}
				if (this.currentComic) this.closeComicInfo();
				const img = this.$refs["img-" + i][0].$el.children[1];
				if (img.className != "v-responsive__content" && img.style.backgroundImage == "") {
					const startTime = Date.now();
					comic.selected = true;
					this.currentComic = comic;
					const preloadImg = setInterval(() => {
						if (Date.now() - startTime < 5000) {
							const newImg = this.$refs["img-" + i][0].$el.children[1];
							if (newImg != img && comic.selected) {
								newImg.style.filter = "blur(2.5px)";
								newImg.style.opacity = 0.3;

								clearInterval(preloadImg);
							}
						} else {
							clearInterval(preloadImg);
						}
					}, 10);
				} else {
					comic.selected = true;
					this.currentComic = comic;
					if (img.className != "v-responsive__content") {
						img.style.filter = "blur(2.5px)";
						img.style.opacity = 0.3;
					}
				}
			},
			archive(id) {
				this.$root.bus.$emit("archive", id);
			},
			deleteComic(id) {
				this.$root.bus.$emit("delete", id);
			},
			share(comic) {
				this.$root.bus.$emit("shareComic", comic);
			},
			addChapterButtons(e) {
				const element = e.target;
				if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= 3.0) {
					if (this.chapters.length == 0) return;
					const chapterIndex = this.currentComic.chapters.findIndex(
						(ch) => ch.chapter == this.chapters[this.chapters.length - 1].chapter
					);
					if (chapterIndex == -1 || chapterIndex - 1 == -1) return;
					let addButtonsToIndex = chapterIndex + 30;
					if (addButtonsToIndex >= this.currentComic.chapters.length)
						addButtonsToIndex = this.currentComic.chapters.length - 1;
					for (let i = chapterIndex + 1; i <= addButtonsToIndex; i++) {
						if (i < this.currentComic.chapters.length) {
							this.chapters.push(this.currentComic.chapters[i]);
						} else {
							break;
						}
					}
				} else if (element.scrollTop <= 3.0) {
					if (this.chapters.length == 0) return;
					const chapterIndex = this.currentComic.chapters.findIndex((ch) => ch.chapter == this.chapters[0].chapter);
					if (chapterIndex == -1 || chapterIndex - 1 == -1) return;
					let addButtonsToIndex = chapterIndex - 30;
					if (addButtonsToIndex < 0) addButtonsToIndex = 0;
					for (let i = chapterIndex - 1; i >= addButtonsToIndex; i--) {
						this.chapters.unshift(this.currentComic.chapters[i]);
					}
					const oldChapter = this.currentComic.chapters[chapterIndex].chapter;
					this.waitForRef("comic-" + this.currentComic.id + "-btn-" + oldChapter).then((ref) => {
						this.$refs.chapterContainer[0].scrollTop = ref[0].$el.offsetTop;
					});
				}
			},
			openComicBrowser(url) {
				window.open(url);
			},
			openComic(comic, chapter) {
				history.pushState({}, "", "/");
				this.$root.bus.$emit("readComic", comic, chapter);
				this.$root.bus.$emit("closeComicInfo");
			},
			scrollTo(comic, ch) {
				const waitForButton = (time) => {
					const btn = this.$refs["comic-" + comic.id + "-btn-" + ch];

					if (!btn || !btn[0] || !btn[0].$el || btn[0].$el.offsetTop == 0) {
						setTimeout(() => {
							if (Date.now() - time < 2000) waitForButton(time);
						}, 10);
					} else {
						this.$refs.chapterContainer[0].scrollTop = btn[0].$el.offsetTop - 50;
						return;
					}
				};
				waitForButton(Date.now());
			},
			waitForRef(ref, time = Date.now()) {
				return new Promise((res, rej) => {
					const r = this.$refs[ref];
					if (!r) {
						if (Date.now() - time < 2000) {
							setTimeout(() => {
								return res(this.waitForRef(ref, time));
							}, 10);
						} else {
							return rej(-1);
						}
					} else {
						return res(r);
					}
				});
			},
		},
	};
</script>

<style scoped>
	.comic-preview-container {
		display: flex;
		justify-content: center;
		gap: 10px;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.comic-preview-container .flex {
		display: flex;
		justify-content: center;
	}
	.exportButton {
		transition: all 0.1s ease-in-out;
	}

	.exportButton:hover {
		background-color: #272727;
	}

	.comicCard {
		margin: 10px;
		transition: all 0.2s ease-in-out;
	}

	/* .comicCard:hover {
    transform: scale(1.05);
}
 */
	.comicCardContent {
		-webkit-user-select: none;
		/* Safari */
		-moz-user-select: none;
		/* Firefox */
		-ms-user-select: none;
		/* IE10+/Edge */
		user-select: none;
		/* Standard */
		position: absolute;
		display: flex;
		flex-direction: column;
		text-align: center;
		height: 100%;
		padding: 20px;
	}

	.comicCardCenter {
		height: 100%;
		width: 100%;
	}

	.comicCardTitle {
		justify-content: center;
		font-size: 20px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		line-height: 20px;
	}

	.comicCardSubtitle {
		color: hsla(0, 0%, 100%, 0.7);
		font-size: 14px;
	}

	.comicCardActions {
		display: flex;

		width: 100%;
		bottom: 0;
	}

	.highlighted {
		border: 2px solid white;
	}

	.chapters {
		position: relative;
		padding: 0;
		padding-bottom: 5px;
		width: 75%;
		height: 70%;
		overflow-y: scroll;
		max-height: 200px;
		border-radius: 4px;
	}
</style>
