<template>
	<div class="info-container">
		<span class="title header" >
			Features
		</span>
		<span class="info-container-content">
			<info-container-card
				:icon="'mdi-bookmark-multiple'"
				:title="'Bookmarks'"
				:text="'All your Comics in one place. No more bookmark hell.'"
			/>
			<info-container-card
				:icon="'mdi-cellphone-link'"
				:title="'Read anywhere'"
				:text="
					'Use this website on your phone, computer or tablet. Just login with your Discord account and enjoy your Comics.'
				"
			/>
			<info-container-card
				:icon="'mdi-book-open-page-variant-outline'"
				:title="'Reader'"
				:text="'Read directly here - no more add filled, scammy websites.'"
			/>
			<info-container-card
				:icon="'mdi-alert-decagram'"
				:title="'Updates'"
				:text="'New chapters will be automatically added, no need to keep track of upload days.'"
			/>
		</span>
	</div>
</template>

<script>
	import infoContainerCard from "./info-container-card.vue";

	export default {
		components: {
			infoContainerCard,
		},
	};
</script>

<style scoped>
	.title.header {
		letter-spacing: 0.2em !important;
		font-size: 1.8rem !important;
		margin-bottom: 24px;
	}
	.info-container {
		width: 100%;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #212121;
	}
	.info-container-content {
		display: flex;
		gap: 10px;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		width: 65%;
	}
</style>
