<template>
	<div class="preview-sites-container">
		<div class="supported-sites-wrapper">
			<span class="title header" style="text-align:center;">
				Supported Sites
			</span>
			<div class="supported-sites">
				<v-avatar size="56">
					<img src="../../assets/icons/asura.png" alt="Asura" />
				</v-avatar>
				<v-avatar size="56">
					<img src="../../assets/icons/flame.png" alt="Flame" />
				</v-avatar>
				<v-avatar size="56">
					<img src="../../assets/icons/reaper.png" alt="Reaper" />
				</v-avatar>
				<v-avatar size="56">
					<img src="../../assets/icons/manganato.png" alt="Manganato" />
				</v-avatar>
				<v-avatar size="56">
					<img src="../../assets/icons/rln.png" alt="ReadLightNovel" />
				</v-avatar>
			</div>
			<span style="letter-spacing: 0.164em;font-weight: 300;">- or just add your own -</span>
		</div>
		<!-- <div class="add-comic-preview-wrapper">
			<v-card>
				<v-card-title>
					<span class="text-h5">Preview</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="4">
								<v-select
									:items="['Manganato', 'Asura', 'Manganelo', 'Reaper', 'Flame', 'Readlightnovel', 'Custom']"
									required
									hint="Provider"
									label="Provider"
									v-model="providerSelect"
									persistent-hint
									return-object
									single-line
								></v-select>
							</v-col>
							<v-col cols="8">
								<v-text-field
									label="Chapter URL"
									required
									hint="The URL of your current chapter"
									persistent-hint
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-if="providerSelect == 'Custom'"
									required
									label="Mainpage URL"
									hint="The URL of the mainpage, where all chapters are listed"
									persistent-hint
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</div> 
		<v-divider></v-divider> -->
		<div class="comic-preview-wrapper">
			<div class="comic-preview">
				<span class="title header" style="text-align:center;">
					Create your own collection
				</span>
				<exampleComics />
			</div>
		</div>
	</div>
</template>

<script>
	import exampleComics from "./example-comics.vue";
	export default {
		components: {
			exampleComics,
		},
		data() {
			return {
				providerSelect: "Custom",
			};
		},
	};
</script>

<style scoped>
	.comic-preview-wrapper {
		width: 100%;
		background: #212121;
		display: flex;
		justify-content: center;
		padding: 20px;
	}
	.comic-preview {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.supported-sites {
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
		gap: 20px;
	}
	.preview-sites-container {
		width: 100%;
		padding-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}
	.supported-sites-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	.v-card {
		border-radius: 20px;
	}
	.title.header {
		letter-spacing: 0.2em !important;
		font-size: 1.8rem !important;
	}
</style>
