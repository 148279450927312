<template>
	<v-snackbar v-model="activated" :timeout="timeout" :style="cssProps">
		<v-icon small class="mr-2">
			mdi-information-outline
		</v-icon>
	
		{{ text }}

		<template v-slot:action="{ attrs }">
			<v-btn color="blue" text v-bind="attrs" @click="close()" small>
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
	export default {
		data: () => ({}),
		props: {
			activate: {
				type: Boolean,
			},
			timeout: {
				default: 4000,
				type: Number,
			},
			text: {
				type: String,
			},
		},
		model: {
			prop: "activate",
			event: "toggle",
		},
		computed: {
			cssProps() {
				return {
					"--timeout": this.timeout + "ms",
				};
			},
			activated: {
				get: function() {
					return this.activate;
				},
				set: function(val) {
					return this.$emit("toggle", val);
				},
			},
		},

		methods: {
			close() {
				this.$emit("toggle", false);
			},
		},
	};
</script>

<style scoped></style>

<style>
	.v-snack__action {
		margin-right: 0 !important;
	}
	.v-snack__wrapper {
		overflow: hidden;
	}
	.v-snack__wrapper::after {
		content: "";
		width: 100%;
		height: 5px;
		background: #2196f3;
		position: absolute;
		bottom: 0;
		animation-name: close;
		animation-duration: var(--timeout);
		animation-timing-function: linear;
		animation-fill-mode: forwards;
	}

	@keyframes close {
		100% {
			width: 0%;
		}
	}
</style>
