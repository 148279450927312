<template>
	<div>
		<v-btn @click="login()" :class="small ? 'small' : ''">
			<v-img v-if="!dashboard" src="../../assets/Discord-Logo.png"></v-img
			><span v-if="!small && !dashboard">Discord Login</span> <span v-if="dashboard">Dashboard</span></v-btn
		>
	</div>
</template>

<script>
	export default {
		props: {
			small: Boolean,
			dashboard: Boolean,
		},
		methods: {
			login() {
				if (!this.dashboard) window.location.replace("/api/discord/login");
				else {
					this.$router.push("/");
				}
			},
		},
	};
</script>

<style scoped>
	.v-btn.small {
		padding: 15px 18px !important;
	}
	.v-btn .v-image {
		width: 36px;
		height: 23px;
	}
	.v-btn {
		padding: 30px 20px !important;
		transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
	}
	.v-btn .v-img span {
		margin-left: 10px;
	}
	.v-btn:hover {
		background: rgb(73, 73, 73);
		/* box-shadow: 0px 3px 1px -2px #2196f3, 0px 2px 2px 0px #2196f3, 0px 0px 0px 0px #2196f3; */
		/* transform: scale(1.05); */
	}
</style>
