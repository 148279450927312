<template>
	<v-card class="info-container-card">
		<v-card-title primary-title>
			<v-icon left>
				{{ icon }}
			</v-icon>
			<span class="text-h6 font-weight-light">{{ title }}</span>
		</v-card-title>
		<v-card-text>
			{{ text }} 
		</v-card-text>
	</v-card>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
			},
			text: {
				type: String,
			},
			icon: {
				type: String,
			},
		},
	};
</script>

<style>
	.info-container-card {
		background-color: #212121 !important;
		box-shadow: none !important;
        width: 450px;
	}
</style>
