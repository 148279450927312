<template>
	<div class="comics" @click.self="closeComicInfo">
		<v-container ref="comicCont" @click.self="closeComicInfo" style="overflow-y: auto;" fluid>
			<v-container @click.self="closeComicInfo">
				<v-layout row wrap @click.self="closeComicInfo">
					<v-flex v-for="(comic, i) in comics" :key="comic.id">
						<v-badge
							v-if="(show == 'True' && !comic.archive) || (show == 'False' && comic.archive)"
							overlap
							:content="
								comic.chapters && comic.chapters.length > 0
									? +(comic.chapters[comic.chapters.length - 1].chapter - comic.chapter).toFixed(2)
									: '/'
							"
							tile
							:value="
								(!currentComic || currentComic.id != comic.id) &&
									comic.chapters &&
									comic.chapters.length > 0 &&
									comic.chapters[comic.chapters.length - 1].chapter != comic.chapter
							"
							transition="expandLeft-transition"
						>
							<v-card class="comicCard" min-width="200">
								<v-img
									:ref="'img-' + i"
									eager
									@click="(e) => openComicInfo(e, comic, i)"
									@error="errorImage(comic)"
									:alt="comic.title"
									:src="comic.image"
									dark
									:aspect-ratio="2 / 3"
									style="cursor: pointer"
								>
									<v-slide-y-transition>
										<v-container v-if="currentComic && currentComic.id == comic.id" class="comicCardContent">
											<v-row
												style="height:20%;justify-content:center;display: flex;flex-direction: column;flex-wrap: nowrap;"
											>
												<v-tooltip bottom max-width="200" open-delay="400" color="#1e1e1e" transition="none">
													<template v-slot:activator="{ on, attrs }">
														<div class="comicCardTitle" @click="openComicBrowser(comic.url)">
															<span v-bind="attrs" v-on="on">{{ comic.title }}</span>
														</div>
													</template>
													<span>{{ comic.title }}</span>
												</v-tooltip>
												<div class="comicCardSubtitle">
													{{ comic.chapter
													}}<span v-if="comic.chapters && comic.chapters.length > 0"
														>/{{ comic.chapters[comic.chapters.length - 1].chapter }}</span
													>
												</div>
											</v-row>
											<v-row style="height:70%;">
												<div class="comicCardCenter">
													<v-btn
														color="primary"
														text
														@click="openComic(comic)"
														style="font-size:30px;margin-top: 5%;margin-bottom: 5%;"
													>
														Read
													</v-btn>
													<v-container
														v-if="comic.chapters && comic.chapters.length > 0"
														ref="chapterContainer"
														class="chapters"
														@scroll="addChapterButtons"
													>
														<v-btn
															:ref="'comic-' + comic.id + '-btn-' + chapter.chapter"
															:class="{ highlighted: chapter.chapter == comic.chapter }"
															style="width:95%;margin-top:5px;margin-left:5px;font-size:10px;"
															v-for="chapter in chapters"
															:key="chapter.url"
															@click="openComic(comic, chapter)"
														>
															Chapter {{ chapter.chapter }}
														</v-btn>
														<v-btn
															style="width:95%;margin-top:5px;margin-left:5px;font-size:10px;"
															disabled
															v-if="getUploadFreq(comic)"
														>
															{{ getUploadFreq(comic).nextDay.toLocaleDateString() }}
														</v-btn>
													</v-container>
												</div>
											</v-row>
											<v-row style="height:10%;align-items: flex-end;margin-top:10%">
												<div class="justify-center comicCardActions">
													<v-btn icon @click="archive(comic.id)">
														<v-icon v-if="!comic.archive">mdi-archive</v-icon>
														<v-icon v-if="comic.archive"> mdi-archive-arrow-up</v-icon>
													</v-btn>
													<v-spacer style="display:flex;justify-content: center;">
														<v-btn icon @click="share(comic)" disabled>
															<v-icon>mdi-export-variant</v-icon>
														</v-btn>
													</v-spacer>
													<v-btn icon @click="deleteComic(comic.id)">
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</div>
											</v-row>
										</v-container>
									</v-slide-y-transition>
								</v-img>
							</v-card>
						</v-badge>
					</v-flex>
					<v-flex v-for="i in 15" :key="i" @click="closeComicInfo">
						<v-card flat style="margin: 10px; background-color: transparent" min-width="200" />
					</v-flex>
				</v-layout>
			</v-container>
			<!--  <div  ref="footer" color="rgb(30 30 30)" style="position:fixed;bottom:0;left:0;width:100%;padding:1vh;background-color:rgb(30,30,30)">
            <v-row justify="center" no-gutters style="background-color: rgb(30 30 30) !important;font-size:10px;">
                   <v-spacer></v-spacer> EXPORT
            </v-row>
        </div> -->
		</v-container>
	</div>
</template>

<style>
	.v-tooltip__content.menuable__content__active {
		opacity: 1 !important;
		border: gray 1px solid !important;
	}
</style>

<style scoped>
	.exportButton {
		transition: all 0.1s ease-in-out;
	}

	.exportButton:hover {
		background-color: #272727;
	}

	.comicCard {
		margin: 10px;
		transition: all 0.2s ease-in-out;
	}

	/* .comicCard:hover {
    transform: scale(1.05);
}
 */
	.comicCardContent {
		-webkit-user-select: none;
		/* Safari */
		-moz-user-select: none;
		/* Firefox */
		-ms-user-select: none;
		/* IE10+/Edge */
		user-select: none;
		/* Standard */
		position: absolute;
		display: flex;
		flex-direction: column;
		text-align: center;
		height: 100%;
		padding: 20px;
	}

	.comicCardCenter {
		height: 100%;
		width: 100%;
	}

	.comicCardTitle {
		justify-content: center;
		font-size: 20px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		line-height: 20px;
	}

	.comicCardSubtitle {
		color: hsla(0, 0%, 100%, 0.7);
		font-size: 14px;
	}

	.comicCardActions {
		display: flex;

		width: 100%;
		bottom: 0;
	}

	.highlighted {
		border: 2px solid white;
	}

	.chapters {
		position: relative;
		padding: 0;
		padding-bottom: 5px;
		width: 75%;
		height: 70%;
		overflow-y: scroll;
		max-height: 200px;
		border-radius: 4px;
	}
</style>

<script>
import { mapState } from "vuex";
	export default {
		data() {
			return {
				// Comic Info Menu
				currentComic: null,
				chapters: [],
			};
		},
		props: ["show"],
		watch: {
			currentComic: {
				immediate: true,

				handler(a, b) {
					if (a) {
						this.chapters = [];
						if (this.currentComic.chapters) {
							const chapterIndex = this.currentComic.chapters.findIndex((ch) => ch.chapter == a.chapter);
							if (chapterIndex == -1) return;

							let firstButtonIndex = chapterIndex - 30;
							let lastButtonIndex = chapterIndex + 30;
							if (firstButtonIndex < 0) firstButtonIndex = 0;
							if (lastButtonIndex >= this.currentComic.chapters.length)
								lastButtonIndex = this.currentComic.chapters.length - 1;

							for (let i = firstButtonIndex; i <= lastButtonIndex; i++) {
								this.chapters.push(this.currentComic.chapters[i]);
							}
							this.scrollTo(this.currentComic, a.chapter);
						}
					} else {
						this.chapters = [];
					}
				},
			},
		},
		mounted() {
			this.adjustContainerHeight();
			window.addEventListener("resize", (x) => {
				this.adjustContainerHeight();
			});
			this.$root.bus.$on("deleteComic", (id) => {
				this.deleteComic(id);
				this.menu = false;
			});
			this.$root.bus.$on("closeComicInfo", () => this.closeComicInfo());
		
		},
		computed: {
			...mapState(["user"]),
			comics() {
				return this.user.comics;
			},
		},
		methods: {
			adjustContainerHeight() {
				this.$refs.comicCont.style.height =
					document.documentElement.clientHeight - this.$refs.comicCont.getBoundingClientRect().y + "px";
			},
			getUploadFreq(comic) {
				if (comic.chapters && comic.chapters.length > 0 && !comic.chapters[0].date) return;
				if (comic.chapters && comic.chapters.length > 1) {
					let i = 0;
					let a = null;
					let b = null;
					let aDate = null;
					let bDate = null;
					do {
						a = comic.chapters[comic.chapters.length - 2 - i];
						b = comic.chapters[comic.chapters.length - 1 - i];
						aDate = new Date(a.date);
						if (aDate == "Invalid Date") aDate = new Date();
						bDate = new Date(b.date);
						if (bDate == "Invalid Date") bDate = new Date();
						i += 1;
					} while (
						comic.chapters.length - 2 - i >= 0 &&
						(aDate == "Invalid Date" || bDate == "Invalid Date" || +aDate == +bDate)
					);
					if (comic.chapters.length - 2 - i < 0 || aDate == "Invalid Date" || bDate == "Invalid Date") return;
					const freq = bDate - aDate;
					return {
						freq: freq / 86400000,
						nextDay: new Date(+bDate + freq),
					};
				}
			},
			errorImage(comic) {
				if (comic.image.charAt(0) != "/") {
					comic.image = "/database/image?url=" + comic.image;
				}
			},
			closeComicInfo() {
				//this.menu = false;
				if (this.currentComic) {
					this.currentComic.selected = false;
					const i = this.comics.findIndex((c) => c.id == this.currentComic.id);
					if (
						this.$refs["img-" + i] &&
						this.$refs["img-" + i][0] &&
						this.$refs["img-" + i][0].$el &&
						this.$refs["img-" + i][0].$el.children[1]
					) {
						const img = this.$refs["img-" + i][0].$el.children[1];
						img.style.filter = "blur(0)";
						img.style.opacity = 1;
					}
					this.chapters = [];
					this.currentComic = null;
				}
			},
			openComicInfo(e, comic, i) {
				if (this.currentComic == comic) {
					this.closeComicInfo();
					return;
				}
				if (this.currentComic) this.closeComicInfo();
				const img = this.$refs["img-" + i][0].$el.children[1];
				if (img.className != "v-responsive__content" && img.style.backgroundImage == "") {
					const startTime = Date.now();
					comic.selected = true;
					this.currentComic = comic;
					const preloadImg = setInterval(() => {
						if (Date.now() - startTime < 5000) {
							const newImg = this.$refs["img-" + i][0].$el.children[1];
							if (newImg != img && comic.selected) {
								newImg.style.filter = "blur(2.5px)";
								newImg.style.opacity = 0.3;

								clearInterval(preloadImg);
							}
						} else {
							clearInterval(preloadImg);
						}
					}, 10);
				} else {
					comic.selected = true;
					this.currentComic = comic;
					if (img.className != "v-responsive__content") {
						img.style.filter = "blur(2.5px)";
						img.style.opacity = 0.3;
					}
				}
			},
			archive(id) {
				this.$root.bus.$emit("archive", id);
			},
			deleteComic(id) {
				this.$root.bus.$emit("delete", id);
			},
			share(comic) {
				this.$root.bus.$emit("shareComic", comic);
			},
			addChapterButtons(e) {
				const element = e.target;
				if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= 3.0) {
					if (this.chapters.length == 0) return;
					const chapterIndex = this.currentComic.chapters.findIndex(
						(ch) => ch.chapter == this.chapters[this.chapters.length - 1].chapter
					);
					if (chapterIndex == -1 || chapterIndex - 1 == -1) return;
					let addButtonsToIndex = chapterIndex + 30;
					if (addButtonsToIndex >= this.currentComic.chapters.length)
						addButtonsToIndex = this.currentComic.chapters.length - 1;
					for (let i = chapterIndex + 1; i <= addButtonsToIndex; i++) {
						if (i < this.currentComic.chapters.length) {
							this.chapters.push(this.currentComic.chapters[i]);
						} else {
							break;
						}
					}
				} else if (element.scrollTop <= 3.0) {
					if (this.chapters.length == 0) return;
					const chapterIndex = this.currentComic.chapters.findIndex((ch) => ch.chapter == this.chapters[0].chapter);
					if (chapterIndex == -1 || chapterIndex - 1 == -1) return;
					let addButtonsToIndex = chapterIndex - 30;
					if (addButtonsToIndex < 0) addButtonsToIndex = 0;
					for (let i = chapterIndex - 1; i >= addButtonsToIndex; i--) {
						this.chapters.unshift(this.currentComic.chapters[i]);
					}
					const oldChapter = this.currentComic.chapters[chapterIndex].chapter;
					this.waitForRef("comic-" + this.currentComic.id + "-btn-" + oldChapter).then((ref) => {
						this.$refs.chapterContainer[0].scrollTop = ref[0].$el.offsetTop;
					});
				}
			},
			openComicBrowser(url) {
				window.open(url);
			},
			openComic(comic, chapter) {
				history.pushState({}, "", "/");
				this.$root.bus.$emit("readComic", comic, chapter);
				this.$root.bus.$emit("closeComicInfo");
			},
			scrollTo(comic, ch) {
				const waitForButton = (time) => {
					const btn = this.$refs["comic-" + comic.id + "-btn-" + ch];

					if (!btn || !btn[0] || !btn[0].$el || btn[0].$el.offsetTop == 0) {
						setTimeout(() => {
							if (Date.now() - time < 2000) waitForButton(time);
						}, 10);
					} else {
						this.$refs.chapterContainer[0].scrollTop = btn[0].$el.offsetTop - 50;
						return;
					}
				};
				waitForButton(Date.now());
			},
			waitForRef(ref, time = Date.now()) {
				return new Promise((res, rej) => {
					const r = this.$refs[ref];
					if (!r) {
						if (Date.now() - time < 2000) {
							setTimeout(() => {
								return res(this.waitForRef(ref, time));
							}, 10);
						} else {
							return rej(-1);
						}
					} else {
						return res(r);
					}
				});
			},
		},
	};
</script>
