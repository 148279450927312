<template>
<v-dialog transition="dialog-top-transition" width="500px" v-model="dialog" dark overlay-opacity="0.9" :persistent="persis" @click:outside="hide">
    <template>
        <v-card>
            <v-card-title>
                Checking for updates
                <v-spacer></v-spacer>
                <v-switch v-model="$root.showAllComics" dense :label="`Show All`"></v-switch>
                <v-progress-linear :value="loadingValue" color="primary" style="width:100%;">
                </v-progress-linear>
            </v-card-title>
            <v-card-text class="mt-3">
                <v-row justify="center" v-if="comics.length == 0 && !canClose">
                    <v-progress-circular size='25' style="height: 2.5vh;width: 2.5vh;" indeterminate color="primary"></v-progress-circular>
                </v-row>
                <v-container style="max-height:200px;overflow-y:auto;" ref="loaderContainer">

                    <v-row justify="center" v-for="comic in comics" :key="comic.id" v-show="$root.showAllComics || !comic.archive">
                        <v-col cols="1" style="padding:4px">
                            <v-icon>mdi-check</v-icon>
                        </v-col>
                        <v-col cols="7" style="padding:4px">
                            {{comic.title}}
                        </v-col>
                        <v-col cols="3" style="padding:4px">
                            {{comic.newChapters}} new Chapter<span v-if="comic.newChapters > 1">s</span>!
                        </v-col>
                    </v-row>

                </v-container>
                <v-card-actions class="justify-end">
                    <v-btn v-if="canClose" text @click="hide">Close</v-btn>
                </v-card-actions>
            </v-card-text>
        </v-card>

    </template>
</v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        loadingValue: 0,
        canClose: false,
        persis: true,
        comics: [],
        updatedComics: [],
    }),
    mounted() {
        this.$root.bus.$on("showLoader", () => this.dialog = true);
        this.$root.bus.$on("hideLoader", () => {
            if (this.dialog == true) {
                this.canClose = true;
                this.persis = false;
            }
        });
        this.$root.bus.$on("loaderValue", (c) => {
            this.dialog = true;
            if (c.title && c.chapters) {
                this.loadingValue = parseInt((c.progress / c.total) * 100);
                if (c.newChapters && c.newChapters > 0) {
                    this.comics.push({
                        title: c.title,
                        chapters: c.chapters,
                        newChapters: c.newChapters,
                        archive: c.archive
                    })
                }
                window.setTimeout(() => {
                    this.$refs.loaderContainer.scrollTo(0, this.$refs.loaderContainer.scrollHeight)
                }, 10);
            }

        });
    },
    methods: {
        hide() {
            if(this.persis)
                return
            this.dialog = false;
            this.comics = [];
            this.loadingValue = 0;
            this.canClose = false;
            this.persis = true;
        }
    },
};
</script>
