<template>
	<!-- https://codepen.io/dhanishgajjar/pen/bjaYYo -->
	<div class="bookContainer">
		<div class="book">
			<div class="back"></div>
			<div class="page6"></div>
			<div class="page5"></div>
			<div class="page4"></div>
			<svg class="loginButtonArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.832 268.832">
				<path
					d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z"
				/>
			</svg>
			<div class="bookmarkContainer">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.919 187.2" class="bookmark">
					<path d="M118.905,476.841v187.2l59.46-59.46,59.46,59.46v-187.2Z" transform="translate(-118.905 -476.841)" />
				</svg>
			</div>
			<div class="page3">
				<span class="reverseText welcomeMessage">Welcome Back, ORACLE</span>
			</div>
			<div class="page2"></div>
			<div class="page1"></div>
			<div class="front">
				<h1>Comic Reader</h1>
			</div>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style scoped>
	.bookmarkContainer {
		height: 98%;
        width: 30px;
		top: 2px;
		background: #2196f3;
        background-color: #2196f3;
		transform-origin: left center;
		transition: transform 0.5s ease-in-out, box-shadow 0.35s ease-in-out;
		transform: translateZ(-10px);
		position: absolute;
        backface-visibility: visible;
	}
	.bookmark {
		width: 30px;
		fill: #2196f3;
		bottom: -30px;
		position: absolute;
	}
	.book:hover .bookmarkContainer {
		transform: rotateY(-148deg) scale(1.1) translateZ(-10px);
	}

	.reverseText {
		transform: rotateY(180deg);
		position: absolute;
	}

	.welcomeMessage {
		width: 100%;
		padding: 15px;
		color: black;
	}

	.loginButtonArrow {
		width: 75px;
		position: absolute;
		fill: transparent;
		top: calc(50% - 37.5px);
		left: calc(160px - 37.5px);
		transition: all 0.1s 0.2s;
	}

	.book:hover .loginButtonArrow {
		fill: #2196f3;
		left: calc(175px - 37.5px);
		transform: translateZ(120px);
	}

	.bookContainer {
		position: relative;
		perspective: 1500px;
	}

	.book {
		transform-style: preserve-3d;
		position: relative;
		height: 300px;
		cursor: pointer;
		backface-visibility: visible;
	}

	.front,
	.back,
	.page1,
	.page2,
	.page3,
	.page4,
	.page5,
	.page6 {
		transform-style: preserve-3d;
		position: absolute;
		width: 200px;
		height: 100%;
		top: 0;
		left: 0;
		transform-origin: left center;
		transition: transform 0.5s ease-in-out, box-shadow 0.35s ease-in-out;
	}

	.front,
	.back {
		background: #212121;
	}

	.front h1 {
		color: white;
		margin: 20px;
        backface-visibility: hidden;
        text-align: center;
	}

	.front,
	.back,
	.page1,
	.page3,
	.page5,
	.page2,
	.page4,
	.page6 {
		border-bottom-right-radius: 0.5em;
		border-top-right-radius: 0.5em;
	}

	.page1,
	.page3,
	.page5,
	.page2,
	.page4,
	.page6 {
		width: 195px;
		left: 2.5px;
		top: 2.5px;
		bottom: 2.5px;
		height: 295px;
	}

	.page1 {
		background: #efefef;
	}

	.page2 {
		background: #efefef;
	}

	.page3 {
		background: #f5f5f5;
	}

	.page4 {
		background: #f5f5f5;
	}

	.page5 {
		background: #fafafa;
	}

	.page6 {
		background: #fdfdfd;
	}

	.book:hover .front {
		transform: rotateY(-160deg) scale(1.1);
		box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
	}

	.book:hover .page1 {
		transform: rotateY(-156deg) scale(1.1);
		box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
	}

	.book:hover .page2 {
		transform: rotateY(-152deg) scale(1.1);
		box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
	}
	.book:hover .page3 {
		transform: rotateY(-148deg) scale(1.1);
		box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
	}

	.book:hover .page4 {
		transform: rotateY(-24deg) scale(1.1);
		box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
	}

	.book:hover .page5 {
		transform: rotateY(-28deg) scale(1.1);
		box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
	}

	.book:hover .page6 {
		transform: rotateY(-32deg) scale(1.1);
		box-shadow: 0 1em 3em 0 rgba(0, 0, 0, 0.2);
	}

	.book:hover .back {
		transform: rotateY(-20deg) scale(1.1);
	}
</style>
