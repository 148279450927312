var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialog)?_c('v-dialog',{attrs:{"scrollable":"","hide-overlay":_vm.fs,"fullscreen":_vm.fs,"transition":"dialog-top-transition","dark":"","width":"75%","overlay-opacity":"0.9","content-class":"elevation-0 dialogClass"},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c('div',{ref:"loaderContainer",staticClass:"loaderContainer"},[(_vm.comic)?_c('v-row',{staticStyle:{"margin":"0","color":"white","font-size":"2vh","align-items":"center"}},[_c('v-btn',{staticClass:"topButton",attrs:{"icon":""},on:{"click":function (e) {
								this$1.close();
								this$1.$root.bus.$emit('readComic', _vm.comic);
							}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showLoader),expression:"!showLoader"}],staticStyle:{"font-size":"3vh","display":"none"}},[_vm._v("mdi-refresh")]),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoader),expression:"showLoader"}],staticStyle:{"height":"2.5vh","width":"2.5vh"},attrs:{"size":"25","indeterminate":""}})],1),_vm._v(" "+_vm._s(_vm.comic.chapter)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"topButton",attrs:{"icon":""},on:{"click":_vm.toggleFullscreen}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fs),expression:"!fs"}],staticStyle:{"font-size":"3vh"}},[_vm._v("mdi-fullscreen")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.fs),expression:"fs"}],staticStyle:{"font-size":"3vh"}},[_vm._v("mdi-fullscreen-exit")])],1),_c('v-btn',{staticClass:"topButton",attrs:{"icon":""},on:{"click":function () {
								_vm.dialog = false;
								_vm.close();
							}}},[_c('v-icon',{staticStyle:{"font-size":"3vh"}},[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('div',{staticClass:"loader"},[_c('div',{ref:"comicContainer",staticClass:"comicContainer",attrs:{"tabindex":"0"}},[_c('div',{ref:"forceHeight"},[_vm._l((_vm.images),function(img,index){return _c('img',{key:img.id,ref:'img-' + index,refInFor:true,staticStyle:{"display":"none","margin":"0.1px auto 0","max-width":"100%","height":"auto"}})}),_vm._l((_vm.text),function(textBlock){return _c('p',{key:textBlock.id},[_vm._v(" "+_vm._s(textBlock.text)+" ")])}),(_vm.showError && _vm.images.length == 0 && _vm.comic)?_c('div',[_c('v-row',{staticStyle:{"margin":"0","color":"white","font-size":"4vh","display":"flex","flex-direction":"column","align-items":"center","text-align":"center"},attrs:{"justify":"center"}},[_vm._v(" Something went wrong :("),_c('br'),_vm._v(" Visit the original Website to read the comic. "),_c('v-btn',{on:{"click":function($event){return _vm.openComic(_vm.comic.url)}}},[_vm._v(" "+_vm._s(_vm.comic.title)+" ")])],1)],1):_vm._e()],2)]),_c('v-row',{staticStyle:{"margin":"-1vh 0 1vh"},attrs:{"justify":"center"}},[_c('v-alert',{staticStyle:{"position":"absolute","z-index":"1"},attrs:{"type":"error","value":_vm.showErrorBox,"transition":"slide-y-reverse-transition"}},[_vm._v("Was not able to fetch all images.")])],1),_c('v-row',{staticStyle:{"margin":"1vh 0px -4vh"},attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"customButton",attrs:{"disabled":_vm.showError || !_vm.comic || (_vm.comic.chapters && +_vm.comic.chapter <= +_vm.comic.chapters[0].chapter)},on:{"click":function($event){return _vm.change(-1)}}},[_vm._v("Prev")]),_c('v-btn',{staticClass:"customButton",attrs:{"disabled":_vm.showError ||
									!_vm.comic ||
									(_vm.comic.chapters && +_vm.comic.chapter >= +_vm.comic.chapters[_vm.comic.chapters.length - 1].chapter)},on:{"click":function($event){return _vm.change(1)}}},[_vm._v("Next")])],1)],1)],1)]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }