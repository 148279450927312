<template>
	<v-app>
		<v-navigation-drawer floating absolute temporary v-model="userMenu" width="225">
			<v-list>
				<v-list-item>
					<v-list-item-avatar>
						<v-img :src="user.icon"></v-img>
					</v-list-item-avatar>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-h6">
							{{ user.name }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-list nav>
				<v-list-item-group>
					<v-list-item @click="openStart">
						<v-list-item-icon>
							<v-icon>mdi-home</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Start</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="exportComics">
						<v-list-item-icon>
							<v-icon>mdi-download</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Export</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon>mdi-discord</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Discord</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item @click="logout">
						<v-list-item-icon>
							<v-icon>mdi-logout-variant</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Logout</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<v-app-bar app style="z-index:11;">
			<div class="topRow">
				<v-btn icon @click="userMenu = !userMenu" style="z-index:10;">
					<v-avatar size="35"
						><v-icon dark v-if="user.icon == ''" large> mdi-account-circle </v-icon
						><img v-if="user.icon != ''" :src="user.icon"
					/></v-avatar>
				</v-btn>
				<add-comic></add-comic>
				<v-spacer> </v-spacer>
				<v-btn icon dark @click="openStart()" v-if="$vuetify.breakpoint.name != 'xs'"
					><v-icon>mdi-home</v-icon></v-btn
				>
				<v-menu
					v-model="calendar"
					:close-on-content-click="false"
					:nudge-width="500"
					offset-y
					nudge-bottom="10"
					content-class="calendarMenu"
					transition="expandBottom-transition"
					z-index="10"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn dark v-bind="attrs" v-on="on" icon>
							<v-icon>mdi-calendar</v-icon>
						</v-btn>
					</template>
					<v-row class="fill-height">
						<v-col style="padding-bottom: 0;">
							<v-sheet height="64">
								<v-toolbar flat>
									<v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
										Today
									</v-btn>
									<v-btn fab text small color="grey darken-2" @click="prev">
										<v-icon small>
											mdi-chevron-left
										</v-icon>
									</v-btn>
									<v-btn fab text small color="grey darken-2" @click="next">
										<v-icon small>
											mdi-chevron-right
										</v-icon>
									</v-btn>
									<v-toolbar-title v-if="$refs.calendar">
										{{ $refs.calendar.title }}
									</v-toolbar-title>
									<v-spacer></v-spacer>
									<v-switch
										hide-details
										v-model="$root.showAllComics"
										@change="comicUpdates"
										dense
										:label="`Show All`"
									></v-switch>
								</v-toolbar>
							</v-sheet>
							<v-sheet style="background-color: #303030 !important;padding: 10px !important;">
								<v-calendar
									@click:event="showEvent"
									@change="comicUpdates"
									:events="events"
									v-model="focus"
									ref="calendar"
									color="primary"
									type="week"
									interval-count="0"
									interval-height="75"
								></v-calendar>
								<v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
									<v-card flat>
										<v-toolbar :color="selectedEvent.color" dark>
											<v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
											<v-spacer></v-spacer>
										</v-toolbar>
										<v-card-text v-html="selectedEvent.details"> </v-card-text>
										<v-card-actions>
											<v-btn text color="secondary" @click="selectedOpen = false">
												Cancel
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-menu>
							</v-sheet>
						</v-col>
					</v-row>
				</v-menu>
				<v-btn icon @click="updateComics">
					<v-icon>mdi-refresh</v-icon>
				</v-btn>
			</div>
		</v-app-bar>
		<v-main>
			<login></login>
			<loader></loader>
			<read-comic></read-comic>
			<want-to-add-comic></want-to-add-comic>
			<v-tabs v-model="tab" background-color="transparent" grow>
				<v-tab>
					Reading
				</v-tab>
				<v-tab>
					Archive
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<comics show="True"></comics>
				</v-tab-item>
				<v-tab-item>
					<comics show="False"></comics>
				</v-tab-item>
			</v-tabs-items>
			<v-row justify="center" style="position: fixed; bottom: 10px; width: 100%;z-index:2;">
				<v-alert type="success" :value="sucAdd" transition="slide-y-reverse-transition">Added Comic!</v-alert>
				<v-alert type="success" :value="archived" transition="slide-y-reverse-transition">{{ archiveText }}</v-alert>
				<v-alert type="error" :value="failAdd" transition="slide-y-reverse-transition"
					>Invalid URL - Could not add Comic</v-alert
				>
				<v-alert type="error" :value="deleteAlert" transition="slide-y-reverse-transition">Deleted!</v-alert>
			</v-row>
			<v-snackbar v-model="shareURL" timeout="-1">
				<div style="overflow: hidden;user-select: all;display: flex;align-items: center;    width: 500px;">
					<div style="white-space: nowrap;overflow: hidden;display:inline-block;">
						{{ shareURLText }}
					</div>
					<v-btn color="primary" text @click="shareURL = false">
						Close
					</v-btn>
				</div>
			</v-snackbar>
		</v-main>
	</v-app>
</template>

<style scoped>
	.calendarMenu {
		max-height: 50vh;
	}

	.circle-gradient {
		background: radial-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5));
	}

	.newChapterAlert {
		position: absolute;
		z-index: 1;
		right: 0;
		margin: 4px;
		font-size: 30px !important;
		background-color: black;
		color: white !important;
		padding: 5px;
		border-radius: 50% !important;
	}

	.topRow {
		width: 100%;
		align-items: center;
		display: flex;
		justify-content: center;
	}

	.loadingCircle {
		position: fixed;
		bottom: 0;
		right: 0;
		margin: 4px;
	}
</style>

<script>
	import addComic from "../components/addComic.vue";
	import Comics from "../components/comics.vue";
	import Loader from "../components/loader.vue";
	import ReadComic from "../components/readComic.vue";
	import WantToAddComic from "../components/wantToAddComic.vue";

	import { mapState, mapActions } from "vuex";

	export default {
		components: {
			addComic,
			ReadComic,
			Loader,
			WantToAddComic,
			Comics,
		},
		data() {
			return {
				selectedOpen: false,
				selectedElement: null,
				selectedEvent: {},
				archiveText: "",
				tab: null,
				focus: "",
				events: [],
				calendar: false,

				// Notifications
				sucAdd: false,
				failAdd: false,
				deleteAlert: false,
				archived: false,

				shareURL: false,
				shareURLText: "",

				//DATA

				userMenu: false,
			};
		},
		mounted() {
			// Check if Shared link is entered
			const urlParams = new URLSearchParams(window.location.search);
			const chapterURL = urlParams.get("chapterURL");
			const mainURL = urlParams.get("mainURL");
			let ln = urlParams.get("ln");
			if (ln == "undefined" || ln == "false") ln = false;
			else if (ln == "true") ln = true;
			if (chapterURL) {
				window.localStorage.setItem(
					"addComic",
					JSON.stringify({
						chapterURL: chapterURL,
						mainURL: mainURL,
						ln: ln,
					})
				);
				window.history.replaceState({}, document.title, "/");
			}

			// reload page on historystate back
			window.onpopstate = (e) => {
				this.$root.bus.$emit("closeComic");
			};

			// Check for updates
			this.updateComics(false);

			// Events
			this.$root.bus.$on("addComic", async (info) => {
				const result = await this.addComic(info);
				if (result) {
					this.sucAdd = true;
					window.setTimeout(() => {
						this.sucAdd = false;
					}, 1000);
				} else {
					this.failAdd = true;
					window.setTimeout(() => {
						this.failAdd = false;
					}, 1000);
				}
			});
			this.$root.bus.$on("shareComic", (comic) => this.share(comic));
			this.$root.bus.$on("delete", (id) => this.delete(id));
			this.$root.bus.$on("archive", (id) => this.archive(id));
		},
		computed: {
			...mapState(["user"]),
			comics() {
				return this.user.comics;
			},
		},
		methods: {
			...mapActions(["getUser", "addComic", "deleteComic", "archiveComic"]),
			openStart() {
				this.$router.push("/start");
			},
			showEvent({ nativeEvent, event }) {
				const open = () => {
					this.selectedEvent = event;
					this.selectedElement = nativeEvent.target;
					requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
				};

				if (this.selectedOpen) {
					this.selectedOpen = false;
					requestAnimationFrame(() => requestAnimationFrame(() => open()));
				} else {
					open();
				}
				nativeEvent.stopPropagation();
			},
			setToday() {
				this.focus = "";
			},
			prev() {
				this.$refs.calendar.prev();
			},
			next() {
				this.$refs.calendar.next();
			},
			share(comic) {
				this.shareURL = true;
				if (comic.chapters && comic.chapters.length > 0) {
					this.shareURLText =
						window.location.href + `?&chapterURL=${comic.chapters[0].url}&mainURL=${comic.mainURL}&ln=${comic.ln}`;
				} else {
					this.shareURLText =
						window.location.href + `?&chapterURL=${comic.url}&mainURL=${comic.mainURL}&ln=${comic.ln}`;
				}
			},
			comicUpdates() {
				let events = [];

				this.comics.forEach((comic) => {
					if (
						!comic.chapters ||
						comic.chapters.length == 0 ||
						comic.chapters[0].date == null ||
						(comic.archive && !this.$root.showAllComics)
					)
						return;
					const nextChapter = this.getUploadFreq(comic);
					if (nextChapter) {
						events.push({
							name: `${comic.title}`,
							start: nextChapter.nextDay,
							details: `Chapter ${+comic.chapters[comic.chapters.length - 1].chapter +
								1} ETA in ${(nextChapter.nextDay.getTime() - new Date().setHours(0, 0, 0, 0)) /
								1000 /
								60 /
								60 /
								24} days.<br> Last Update on: ${nextChapter.lastDay.toLocaleDateString()}, expecting update every ${
								nextChapter.freq
							} days.`,
						});
					}
				});
				this.events = events;
				return events;
			},
			getUploadFreq(comic) {
				if (comic.chapters && comic.chapters.length > 0 && !comic.chapters[0].date) return;
				if (comic.chapters && comic.chapters.length > 1) {
					let i = 0;
					let a = null;
					let b = null;
					let aDate = null;
					let bDate = null;
					do {
						a = comic.chapters[comic.chapters.length - 2 - i];
						b = comic.chapters[comic.chapters.length - 1 - i];
						aDate = new Date(a.date);
						if (aDate == "Invalid Date") aDate = new Date().setHours(0, 0, 0, 0);
						bDate = new Date(b.date);
						if (bDate == "Invalid Date") bDate = new Date().setHours(0, 0, 0, 0);
						i += 1;
					} while (
						comic.chapters.length - 2 - i >= 0 &&
						(aDate == "Invalid Date" || bDate == "Invalid Date" || +aDate == +bDate)
					);
					if (comic.chapters.length - 2 - i < 0 || aDate == "Invalid Date" || bDate == "Invalid Date") return;
					const freq = bDate - aDate;
					return {
						freq: freq / 86400000,
						nextDay: new Date(+bDate + freq),
						lastDay: new Date(bDate),
					};
				}
			},
			addComicFromUrl(chapterURL, mainURL, ln) {
				fetch("/database/addComic", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						chapterURL: chapterURL,
						mainURL: mainURL,
						provider: "Custom",
						add: false,
						ln: ln,
					}),
					credentials: "same-origin",
				})
					.then((result) => {
						if (result.status >= 200 && result.status <= 299) {
							return result.json();
						} else {
							throw Error(result.statusText);
						}
					})
					.then((info) => {
						this.$root.bus.$emit("showWantToAddComic", info);
					})
					.catch((e) => {});
			},
			logout() {
				window.location.replace("/api/discord/logout");
			},
			changeTheme() {
				this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			},
			updateComics(force) {
				fetch("/database/checkForNewChapters", {
					method: "POST",
					credentials: "same-origin",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						force: force,
					}),
				})
					.then((result) => {
						if (result.status >= 200 && result.status <= 299) {
							return result.body.getReader();
						} else {
							throw Error(result.status);
						}
					})
					.then(async (reader) => {
						await (async (reader) => {
							let done, value;
							let showedLoader = false;
							while (!done) {
								({ value, done } = await reader.read());
								if (done) {
									return true;
								}
								try {
									const JSONStrings = new TextDecoder("utf-8")
										.decode(value)
										.split("{")
										.map((js) => "{" + js)
										.splice(1);
									JSONStrings.forEach((str) => {
										const parsedString = JSON.parse(str);
										if (parsedString.progress != null) this.$root.bus.$emit("loaderValue", parsedString);
									});
								} catch (e) {
									console.log(new TextDecoder("utf-8").decode(value));
									console.log(e);
								}
							}
						})(reader);
						this.$root.bus.$emit("hideLoader");
						const result = await this.getUser();
						if (!result) console.error("Something went wrong while fetching data");
					});
			},
			exportComics() {
				window.location.replace("/database/export");
			},
			async delete(id) {
				const result = await this.deleteComic(id);
				if (result) {
					this.deleteAlert = true;
					window.setTimeout(() => {
						this.deleteAlert = false;
					}, 1000);
				} else {
					console.error("Something went wrong while deleting comic");
				}
			},
			async archive(id) {
				const result = await this.archiveComic(id);
				if (result) {
					this.archived = true;
					this.archiveText = result.archived ? "Archived!" : "Set to reading!";
					window.setTimeout(() => {
						this.archived = false;
					}, 1000);
				} else {
					console.error("Something went wrong while archiving comic");
				}
			},
		},
	};
</script>
