var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"position":"absolute"}},[_c('v-row',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"absolute","margin-left":"50%","margin-right":"50%","margin-top":"25px"},attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Add Comic ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Comic")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":[
										'Manganato',
										'Asura',
										'Manganelo',
										'Reaper',
										'Flame',
										'Webtoon',
										'Readlightnovel',
										'Custom' ],"required":"","hint":"Provider","label":"Provider","persistent-hint":"","return-object":"","single-line":""},model:{value:(_vm.providerSelect),callback:function ($$v) {_vm.providerSelect=$$v},expression:"providerSelect"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Chapter URL","required":""},model:{value:(_vm.chapterURL),callback:function ($$v) {_vm.chapterURL=$$v},expression:"chapterURL"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.providerSelect == 'Custom')?_c('v-text-field',{attrs:{"required":"","label":"Mainpage URL"},model:{value:(_vm.mainURL),callback:function ($$v) {_vm.mainURL=$$v},expression:"mainURL"}}):_vm._e(),(_vm.providerSelect == 'Manganato')?_c('v-autocomplete',{attrs:{"item-value":"name","hide-no-data":"","loading":_vm.loading,"items":_vm.items,"color":"white","item-text":"name","return-object":"","label":"Search","search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.searchModel),callback:function ($$v) {_vm.searchModel=$$v},expression:"searchModel"}}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.addComic()}}},[_vm._v(" Add ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }