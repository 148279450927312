<template>
	<v-container style="position:absolute">
		<v-row style="display: flex;align-items: center;justify-content: center;">
			<v-dialog v-model="dialog" max-width="600px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="primary"
						dark
						v-bind="attrs"
						v-on="on"
						style="position: absolute;margin-left: 50%;margin-right: 50%;margin-top: 25px;"
					>
						Add Comic
					</v-btn>
				</template>
				<v-card>
					<v-card-title>
						<span class="text-h5">Comic</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="4">
									<v-select
										:items="[
											'Manganato',
											'Asura',
											'Manganelo',
											'Reaper',
											'Flame',
											'Webtoon',
											'Readlightnovel',
											'Custom',
										]"
										required
										hint="Provider"
										label="Provider"
										v-model="providerSelect"
										persistent-hint
										return-object
										single-line
									></v-select>
								</v-col>
								<v-col cols="8">
									<v-text-field v-model="chapterURL" label="Chapter URL" required></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-text-field
										v-if="providerSelect == 'Custom'"
										required
										v-model="mainURL"
										label="Mainpage URL"
									></v-text-field>
									<v-autocomplete
										v-if="providerSelect == 'Manganato'"
										item-value="name"
										hide-no-data
										:loading="loading"
										:items="items"
										color="white"
										item-text="name"
										return-object
										v-model="searchModel"
										label="Search"
										:search-input.sync="search"
									></v-autocomplete>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialog = false">
							Close
						</v-btn>
						<v-btn color="blue darken-1" text @click="addComic()">
							Add
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		data: () => ({
			dialog: false,
			chapterURL: "",
			mainURL: "",
			providerSelect: "Manganato",
			searchResults: [],
			search: null,
			loading: false,
			searchModel: null,
		}),
		computed: {
			items() {
				return this.searchResults.map((c) => {
					c.name = c.name.replaceAll('<span style="color: #FF530D;font-weight: bold;">', "");
					c.name = c.name.replaceAll("</span>", "");
					return c;
				});
			},
		},
		watch: {
			search(searchWord) {
				if (!searchWord || this.isLoading) return;
				if (!searchWord.match(/\s*?(\S)\s*?(\S)\s*?(\S)/)) return;
				this.loading = true;
				fetch("/database/search", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						searchWord: searchWord,
					}),
				})
					.then(async (x) => {
						const resultJSON = await x.json();
						this.searchResults = resultJSON;
						this.searchResults = this.searchResults.map((comic) => {
							comic.chapterURL = comic["link_story"] + "/chapter-0";
							return comic;
						});
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => (this.loading = false));
			},
			searchModel(val) {
				if (val) this.chapterURL = this.searchModel.chapterURL;
			},
		},
		methods: {
			addComic() {
				if (this.providerSelect == "Custom" && (this.chapterURL == "" || this.mainURL == "")) return;
				this.$root.bus.$emit("addComic", {
					chapterURL: this.chapterURL,
					mainURL: this.mainURL,
					provider: this.providerSelect,
					add: true,
				});
				this.chapterURL = "";
				this.mainURL = "";
				this.search = null;
				this.searchModel = null;
				this.dialog = false;
			},
		},
	};
</script>
